import React, { useState, useCallback } from 'react'
import { Form, Button, Container, Col, Accordion } from 'react-bootstrap'
import FeatherIcon from '@components/shared/FeatherIcon'
import { Question } from '@interfaces/instructors'

const { I18n } = window

interface FindInstructorModalProps {
  instructorDetailQuestions
  data
  handleFixedResponseChange
  handleFreeResponseChange
  handleCheckboxChange
  handleQueryChange
}

const FindInstructorModal: React.FC<FindInstructorModalProps> = ({
  instructorDetailQuestions,
  data,
  handleFixedResponseChange,
  handleFreeResponseChange,
  handleCheckboxChange,
  handleQueryChange,
}) => {
  const [chevronUp, setChevronUp] = useState<boolean>(false)
  const toggleChevron = () => {
    setChevronUp(!chevronUp)
  }

  const isCheckboxChecked = useCallback(
    (question: Question, response: string): boolean => {
      const detail = data.details.find((d) => d.instructor_detail_question_id === question.id)

      return !!(detail && detail.value && detail.value.includes(response))
    },
    [data]
  )

  const renderCheckboxesQuestion = (question: Question): JSX.Element[] => {
    return question.question_responses.map((qr) => (
      <Form.Check
        label={qr.value}
        type="checkbox"
        checked={isCheckboxChecked(question, qr.value)}
        onChange={handleCheckboxChange(question, qr.value)}
        key={qr.id}
      />
    ))
  }

  const renderFixedResponsesQuestion = (question: Question, value: string): JSX.Element => {
    return (
      <Form.Control as="select" onChange={handleFixedResponseChange(question)} value={value}>
        <option key="0"></option>
        {question.question_responses.map((qr) => (
          <option value={qr.value} key={qr.id}>
            {qr.value}
          </option>
        ))}
      </Form.Control>
    )
  }

  const renderFreeResponseQuestion = (question: Question, value: string): JSX.Element => {
    return <Form.Control type="text" onChange={handleFreeResponseChange(question)} value={value} />
  }

  const renderFormControl = (question: Question) => {
    const detail = data.details.find((d) => d.instructor_detail_question_id === question.id)
    const value = detail ? detail.value : ''
    if (question.question_responses.length > 0) {
      if (question.input_type === 'checkboxes') {
        return renderCheckboxesQuestion(question)
      }
      return renderFixedResponsesQuestion(question, value)
    }
    return renderFreeResponseQuestion(question, value)
  }

  return (
    <fieldset>
      <Container>
        <Accordion>
          <Form.Row>
            <Col md={9}>
              <Form.Group controlId="form.question1">
                <Form.Label>{I18n.t('views.search_instructors_form.name_or_email')}</Form.Label>
                <Form.Control
                  type="text"
                  name="nameOrEmail"
                  onChange={handleQueryChange}
                  value={data.query}
                />
              </Form.Group>
            </Col>
            {instructorDetailQuestions.length > 0 && (
              <Col md={3}>
                <Accordion.Toggle
                  as={Button}
                  variant="link"
                  eventKey="0"
                  onClick={toggleChevron}
                  style={{ textDecoration: 'none' }}
                  aria-expanded="false"
                  aria-controls="eventKey0"
                  aria-label={I18n.t('views.search_instructors_form.more_filters')}
                  className="mt-4 text-green-800">
                  {I18n.t('views.search_instructors_form.more_filters')}
                  <FeatherIcon
                    name={chevronUp ? 'chevron-up' : 'chevron-down'}
                    className="stroke-green-800"
                  />
                </Accordion.Toggle>
              </Col>
            )}
          </Form.Row>
          <Accordion.Collapse eventKey="0">
            <React.Fragment>
              {instructorDetailQuestions.map((question, index) => {
                return (
                  <Form.Row className="mt-4" key={index}>
                    <Col md={9}>
                      <Form.Group controlId={`question${index}`}>
                        <Form.Label>{question.question}</Form.Label>
                        {renderFormControl(question)}
                      </Form.Group>
                    </Col>
                  </Form.Row>
                )
              })}
            </React.Fragment>
          </Accordion.Collapse>
        </Accordion>
      </Container>
    </fieldset>
  )
}

export default FindInstructorModal
