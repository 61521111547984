import React from 'react'
import { Question, CustomerSurveyQuestionResponse } from '@interfaces/surveys'

import QuestionInputNumber from './QuestionInputNumber'
import QuestionInputCheckbox from './QuestionInputCheckbox'
import QuestionInputDate from './QuestionInputDate'
import QuestionInputHeightWeight from './QuestionInputHeightWeight'
import QuestionInputText from './QuestionInputText'
import QuestionInputDropdown from './QuestionInputDropdown'
import QuestionInputRadioButton from './QuestionInputRadioButton'
import QuestionInputMulti from './QuestionInputMulti'

interface QuestionResponseInputsProps {
  question: Question
  response: CustomerSurveyQuestionResponse
  baseInputName: string
  controlId: string
  multiInput: boolean
  onStaticResponseChange(value): void
  onActionableResponseChange(value): void
}

const QuestionResponseInputs: React.FC<QuestionResponseInputsProps> = ({
  question,
  response,
  baseInputName,
  controlId,
  multiInput,
  onStaticResponseChange,
  onActionableResponseChange,
}) => {
  const inputProps = {
    question,
    response,
    baseInputName,
    controlId,
    multiInput,
  }

  switch (question.input_type) {
    case 'number':
      return <QuestionInputNumber {...inputProps} handleResponseChange={onStaticResponseChange} />
    case 'date':
      return <QuestionInputDate {...inputProps} handleResponseChange={onStaticResponseChange} />
    case 'height':
    case 'weight':
      return (
        <QuestionInputHeightWeight {...inputProps} handleResponseChange={onStaticResponseChange} />
      )
    case 'checkboxes':
      return <QuestionInputCheckbox {...inputProps} handleResponseChange={onStaticResponseChange} />
    case 'dropdown':
      return (
        <QuestionInputDropdown {...inputProps} handleResponseChange={onActionableResponseChange} />
      )
    case 'radio_buttons':
      return (
        <QuestionInputRadioButton
          {...inputProps}
          handleResponseChange={onActionableResponseChange}
        />
      )
    case 'multi_input':
      return <QuestionInputMulti {...inputProps} />
    default:
      return <QuestionInputText {...inputProps} handleResponseChange={onStaticResponseChange} />
  }
}

export default QuestionResponseInputs
