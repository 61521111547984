import React from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import StripePaymentElement from './StripePaymentElement'

const emStripePubKey = window.__STOREFRONT__.emStripePubKey
const stripePromise = loadStripe(emStripePubKey)

const { I18n } = window

const appearance = {
  theme: 'stripe',
  variables: {
    fontFamily: "'Poppins', 'Roboto', 'Helvetica', 'Arial', sans-serif",
    gridRowSpacing: '20px',
  },
  rules: {
    '.Label': {
      fontWeight: 700,
      marginBottom: '8px',
    },
  },
}

interface PaymentFormProps {
  clientSecret: string
  capacityUrl: string
  registrationCount: number
  successUrl: string
}

const PaymentForm: React.FC<PaymentFormProps> = ({
  clientSecret,
  capacityUrl,
  registrationCount,
  successUrl,
}) => {
  const options = {
    clientSecret: clientSecret,
    appearance: appearance,
  }

  return (
    // @ts-ignore
    <Elements stripe={stripePromise} options={options}>
      <StripePaymentElement
        capacityUrl={capacityUrl}
        registrationCount={registrationCount}
        successUrl={successUrl}
      />
    </Elements>
  )
}

export default PaymentForm
