import React, { Fragment } from 'react'
import Form from 'react-bootstrap/Form'
import classNames from 'classnames'
import FeatherIcon from '@components/shared/FeatherIcon'

import { EventProduct } from '@interfaces/events'

const { I18n } = window

interface EventProductFormProps {
  eventProduct: EventProduct
  index: number
  formatCurrency(programProduct: number): string
  onRemoveEventProduct(eventProduct: EventProduct): void
  onEventPriceUpdate(eventProduct: EventProduct, newPrice: number): void
}

const EventProductForm: React.FC<EventProductFormProps> = ({
  eventProduct,
  index,
  formatCurrency,
  onRemoveEventProduct,
  onEventPriceUpdate,
}) => {
  const productInputName = `program_event[event_products_attributes][${index}]`
  const formattedEventPrice = (eventProduct.price_cents / 100).toString()

  const priceLessThanMin = eventProduct.price_cents < eventProduct.min_price_cents
  const priceMoreThanMax = eventProduct.price_cents > eventProduct.max_price_cents
  const isInvalid = eventProduct.customizable && (priceLessThanMin || priceMoreThanMax)
  const errorText = priceLessThanMin
    ? I18n.t('views.event_products.price_must_be_more', {
        min_price: formatCurrency(eventProduct.min_price_cents),
      })
    : I18n.t('views.event_products.price_must_be_less', {
        max_price: formatCurrency(eventProduct.max_price_cents),
      })

  return (
    <Fragment>
      <input type="hidden" name={`${productInputName}[id]`} value={eventProduct.id} />

      {eventProduct._destroy && (
        <input type="hidden" name={`${productInputName}[_destroy]`} value="1" />
      )}

      {!eventProduct._destroy && (
        <Fragment>
          <input
            type="hidden"
            name={`${productInputName}[program_product_id]`}
            value={eventProduct.program_product_id}
          />
          <div
            className={classNames(
              'p-3 rounded-top d-flex justify-content-between align-items-center',
              {
                'bg-gray-100': eventProduct.permission_type == 'optional',
                'bg-gray-200': eventProduct.permission_type == 'required',
                'mb-2 rounded-bottom': eventProduct.description.length == 0,
              }
            )}
          >
            <div>
              <p className="mb-1 font-size-sm font-weight-medium">{eventProduct.name}</p>
              <div className="d-flex align-items-center">
                <Form.Group
                  className="mb-0 price-input"
                  controlId={`event_product_price-${eventProduct.program_product_id}`}
                >
                  <Form.Label hidden>{I18n.t('views.event_products.price')}</Form.Label>
                  <Form.Control
                    type="number"
                    value={formattedEventPrice}
                    disabled={!eventProduct.customizable}
                    onChange={(e) => onEventPriceUpdate(eventProduct, e.currentTarget.value * 100)}
                    className={classNames('bg-white', {
                      'text-gray-600': !eventProduct.customizable,
                      'border border-green-400': eventProduct.customizable,
                    })}
                    isInvalid={isInvalid}
                  />
                  <input
                    type="hidden"
                    name={`${productInputName}[price_cents]`}
                    value={eventProduct.price_cents}
                  />
                </Form.Group>
                <p className="font-size-sm text-gray-700 ml-2 mb-0">
                  {eventProduct.customizable
                    ? I18n.t('views.event_products.price_range_hint', {
                        min_price: formatCurrency(eventProduct.min_price_cents),
                        max_price: formatCurrency(eventProduct.max_price_cents),
                      })
                    : I18n.t('views.event_products.not_customizable')}
                </p>
              </div>
              {isInvalid && <div className="text-danger font-size-sm pt-2">{errorText}</div>}
            </div>
            <div>
              {eventProduct.permission_type == 'required' ? (
                <p className="text-uppercase overline text-gray-600 font-weight-medium mb-0">
                  {I18n.t('views.event_products.required')}
                  <br />
                  {I18n.t('views.event_products.by_program')}
                </p>
              ) : (
                <div>
                  <button
                    type="button"
                    className="btn link-dark btn-icon-circle"
                    aria-label={I18n.t('views.event_products.remove_product')}
                    onClick={() => onRemoveEventProduct(eventProduct)}
                  >
                    <FeatherIcon name="x" className={`stroke-gray-800`} />
                  </button>
                </div>
              )}
            </div>
          </div>
          {eventProduct.description.length > 0 ? (
            <div className="mb-2 py-2 px-3 rounded border border-gray-100 text-gray-700 font-size-sm">
              {eventProduct.description}
            </div>
          ) : null}
        </Fragment>
      )}
    </Fragment>
  )
}

export default EventProductForm
