import React from 'react'

import SectionHeader from '@components/SectionHeader'
import { Locale } from '@interfaces/global'
import { EventsProgram } from '@interfaces/events'

import {
  EventNameInputField,
  EventNameSelectField,
  EventProgramField,
  EventLanguageSelectField,
} from './components'

const { I18n } = window

interface GeneralInformationSectionProps {
  onProgramChangeEvent(e: React.ChangeEvent<HTMLSelectElement>): void
  onEventNameChangeEvent(e: React.ChangeEvent<HTMLInputElement>): void
  onEventLocaleChangeEvent(e: React.ChangeEvent<HTMLSelectElement>): void
  programs: EventsProgram[]
  program: EventsProgram
  value: string
  locales: Locale[]
  eventLocale: Locale
}

const GeneralInformationSection: React.FC<GeneralInformationSectionProps> = ({
  onProgramChangeEvent,
  onEventNameChangeEvent,
  onEventLocaleChangeEvent,
  programs,
  program,
  value,
  locales,
  eventLocale,
}) => {
  const eventNameSetting = program.event_settings.event_name_setting
  const isConfiguredName = eventNameSetting === 'configured_name'
  const isProgramName = eventNameSetting === 'program_name'

  return (
    <fieldset>
      <SectionHeader text={I18n.t('views.events.general_info')} />

      <EventProgramField
        onChange={onProgramChangeEvent}
        programs={programs}
        programId={program.id}
      />

      {isConfiguredName && <EventNameSelectField program={program} value={value} />}
      {!isConfiguredName && (
        <EventNameInputField
          onChange={onEventNameChangeEvent}
          disabled={isProgramName}
          value={value}
        />
      )}
      {locales.length > 1 && (
        <EventLanguageSelectField
          onChange={onEventLocaleChangeEvent}
          selected={eventLocale}
          locales={locales}
        />
      )}
    </fieldset>
  )
}

export default GeneralInformationSection
