import L from 'leaflet'
import 'leaflet/dist/leaflet.css'

const leafletMarker = require('leaflet/dist/images/marker-icon.png')
const leafletMarker2x = require('leaflet/dist/images/marker-icon-2x.png')
const leafletMarkerShadow = require('leaflet/dist/images/marker-shadow.png')

L.Icon.Default.prototype.options.imagePath = location.origin
L.Icon.Default.prototype.options.iconUrl = leafletMarker
L.Icon.Default.prototype.options.iconRetinaUrl = leafletMarker2x
L.Icon.Default.prototype.options.shadowUrl = leafletMarkerShadow
