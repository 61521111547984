import React, { useState, useCallback } from 'react'
import { Form, Button, Modal, Col } from 'react-bootstrap'
import useFetch from 'use-http'
import qs from 'qs'
import { compact } from 'lodash'
import { Instructor, EventsProgram } from '@interfaces/events'
import FeatherIcon from '@components/shared/FeatherIcon'
import { Question } from '@interfaces/instructors'
import FindInstructorModal from './FindInstructorModal'

const { I18n } = window

interface SearchInstructorsProps {
  instructors: Instructor[]
  instructorDetailQuestions
  program: EventsProgram
  onAddInstructor?
  immediatelySubmit: boolean
  eventId?: number
}

interface Data {
  query: string
  details: Array<{
    instructor_detail_question_id: number
    value?: string
    type: string
  }>
}

const SearchInstructors: React.FC<SearchInstructorsProps> = ({
  instructors,
  instructorDetailQuestions,
  program,
  onAddInstructor,
  immediatelySubmit,
  eventId,
}) => {
  const [show, setShow] = useState<boolean>(false)
  const [instructorList, setInstructorList] = useState([])
  const [data, setData] = useState<Data>({
    query: '',
    details: [],
  })
  const searchQuery = qs.stringify({
    program_id: program.id,
    search: data || {},
  })

  const { get, loading } = useFetch(`/dashboard/em/event_instructors/search.json?${searchQuery}`)

  const fetchInstructors = async () => {
    const res = await get()
    const instructorIds = instructors.map((instructor) => instructor.customer_id)
    const filteredResults = res.map((instructor) => {
      if (!instructorIds.includes(instructor.id)) {
        return instructor
      }
    })
    setInstructorList(compact(filteredResults))
  }

  const handleQueryChange = useCallback(
    (e) => {
      if (e.target.value.length > 0) {
        setData({
          ...data,
          query: e.target.value,
        })
      } else {
        setData({
          ...data,
          query: null,
        })
      }
    },
    [data]
  )

  const handleFreeResponseChange = (question: Question) => (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ): void => {
    const otherDetails = data.details.filter((d) => d.instructor_detail_question_id !== question.id)

    if (e.target.value.length > 0) {
      setData({
        ...data,
        details: [
          ...otherDetails,
          {
            instructor_detail_question_id: question.id,
            type: 'free',
            value: e.target.value,
          },
        ],
      })
    } else {
      setData({
        ...data,
        details: otherDetails,
      })
    }
  }

  const handleFixedResponseChange = (question: Question) => (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    const otherDetails = data.details.filter((d) => d.instructor_detail_question_id !== question.id)

    if (e.target.value.length > 0) {
      setData({
        ...data,
        details: [
          ...otherDetails,
          {
            instructor_detail_question_id: question.id,
            type: 'fixed',
            value: e.target.value,
          },
        ],
      })
    } else {
      setData({
        ...data,
        details: otherDetails,
      })
    }
  }

  const handleCheckboxChange = (question: Question, value: string) => (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const detail = data.details.find((d) => d.instructor_detail_question_id === question.id)
    const otherDetails = data.details.filter((d) => d.instructor_detail_question_id !== question.id)

    let newValues = detail ? detail.value.split(';;') : []
    if (e.target.checked) {
      newValues = [...newValues, value]
    } else {
      newValues = newValues.filter((v) => v !== value)
    }

    if (newValues.length > 0) {
      setData({
        ...data,
        details: [
          ...otherDetails,
          {
            instructor_detail_question_id: question.id,
            type: 'fixed',
            value: newValues.join(';;'),
          },
        ],
      })
    } else {
      setData({
        ...data,
        details: otherDetails,
      })
    }
  }

  const onSearch = () => {
    fetchInstructors()
  }

  const clearSearch = () => {
    setData({
      query: '',
      details: [],
    })
    setInstructorList([])
  }

  const cancelSearch = () => {
    clearSearch()
    setInstructorList([])
    setShow(false)
  }

  const addInstructor = (e) => {
    const instructor = instructorList.filter((i) => i.id === parseFloat(e.target.value))
    const formattedInstructor = {
      customer_id: instructor[0].id,
      email: instructor[0].email_address,
      full_name: instructor[0].first_name + ' ' + instructor[0].last_name,
      is_primary: false,
    }
    onAddInstructor(formattedInstructor)
    setShow(false)
    clearSearch()
  }

  return (
    <fieldset>
      <Form.Row className="ml-0">
        <Button onClick={() => setShow(true)}>
          {I18n.t('views.events.add_instructor')}
          <FeatherIcon name="plus-circle" className="ml-2" />
        </Button>
        <Modal show={show} backdrop="static" onHide={() => setShow(false)} size="lg" centered>
          <Modal.Body className="mx-3 my-3">
            <FindInstructorModal
              instructorDetailQuestions={instructorDetailQuestions}
              data={data}
              handleFixedResponseChange={handleFixedResponseChange}
              handleFreeResponseChange={handleFreeResponseChange}
              handleCheckboxChange={handleCheckboxChange}
              handleQueryChange={handleQueryChange}
            />
            <Form.Row className="mt-5 ml-0 pl-2">
              <Col md={3}>
                <Button type="submit" className="w-100" onClick={onSearch} disabled={loading}>
                  {I18n.t('common.search')}
                </Button>
              </Col>
              <Col md={3}>
                <Button variant="outline-primary" className="w-100" onClick={clearSearch}>
                  {I18n.t('common.clear_search')}
                </Button>
              </Col>
              <Col md={{ span: 3, offset: 3 }}>
                <Button variant="dark" className="w-100" onClick={cancelSearch}>
                  {I18n.t('common.cancel')}
                </Button>
              </Col>
            </Form.Row>
            {instructorList && (
              <Form.Row className="mt-5 ml-0 pl-2">
                <ul className="list-unstyled mb-4 w-100">
                  {instructorList.map((instructor, index) => {
                    return (
                      <li className="card mb-3 p-2" key={index}>
                        <Form.Row>
                          <Col md={10}>
                            <p className="card-title h6 mb-1">
                              {instructor.first_name + ' ' + instructor.last_name}
                            </p>
                            <span className="font-weight-light">{instructor.email_address}</span>
                          </Col>
                          <Col md={2}>
                            {immediatelySubmit ? (
                              <Form
                                action={`/dashboard/em/assigned_events/${eventId}/event_instructors`}
                                method="POST">
                                <input
                                  type="hidden"
                                  name="authenticity_token"
                                  value={window.__STOREFRONT__.formToken['X-CSRF-Token']}
                                />
                                <input
                                  type="hidden"
                                  name="event_instructor[customer_id]"
                                  value={instructor.id}
                                />
                                <input
                                  type="hidden"
                                  name="event_instructor[is_primary]"
                                  value="0"
                                />
                                <Button className="w-100" type="submit">
                                  {I18n.t('common.add')}
                                </Button>
                              </Form>
                            ) : (
                              <Button
                                className="w-100"
                                value={instructor.id}
                                onClick={addInstructor}>
                                {I18n.t('common.add')}
                              </Button>
                            )}
                          </Col>
                        </Form.Row>
                      </li>
                    )
                  })}
                </ul>
              </Form.Row>
            )}
          </Modal.Body>
        </Modal>
      </Form.Row>
    </fieldset>
  )
}

export default SearchInstructors
