import { Controller } from 'stimulus'
import { Modal } from 'bootstrap.native'
// @ts-ignore
import focusLock from 'dom-focus-lock'

export default class extends Controller {
  urlValue: any
  modal: any
  static values = { url: String }

  connect() {
    this.modal = new Modal(this.element, {
      backdrop: 'static',
      keyboard: false,
    })

    focusLock.on(this.element)

    this.load()

    // Force modal to be reinitialized if user clicks away before modal opens
    document.addEventListener('turbolinks:click', () => (this.modal = null), false)
  }

  disconnect() {
    if (this.modal) {
      this.modal.hide()
    }

    focusLock.off(this.element)
  }

  load() {
    fetch(this.urlValue)
      .then((res) => {
        if (res.ok) {
          return res.text()
        } else {
          // Raise an error and prevent modal from opening if
          // server responds w/ errors
          throw res
        }
      })
      .then((html) => {
        if (!html || !this.modal) {
          return
        }

        this.element.innerHTML = html
        this.modal.show()
        window.initBSN()
      })
      .catch((res) => {
        console.error(`Could not fetch required customer details - ${res.statusText}`)
        throw res
      })
  }

  onSubmitSuccess(_event) {
    this.modal.hide()
  }

  onSubmitError(event) {
    const [_data, _status, xhr] = event.detail
    this.element.innerHTML = xhr.response
    window.initBSN()
  }
}
