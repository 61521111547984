import React from 'react'
import { Form } from 'react-bootstrap'
import { Question, CustomerSurveyQuestionResponse } from '@interfaces/surveys'

interface QuestionInputTextProps {
  question: Question
  baseInputName: string
  controlId: string
  response: CustomerSurveyQuestionResponse
  handleResponseChange(value: string): void
  multiInput?: boolean
}

const QuestionInputText: React.FC<QuestionInputTextProps> = ({
  question,
  baseInputName,
  controlId,
  response,
  handleResponseChange,
  multiInput,
}) => {
  const inputType = question.max_characters && question.max_characters < 100 ? 'input' : 'textarea'
  const labelClass = multiInput ? 'font-size-sm' : 'font-weight-medium'

  return (
    <Form.Group controlId={controlId}>
      <Form.Label className={labelClass}>
        {question.question} {question.required || multiInput ? '*' : null}
      </Form.Label>
      <Form.Control
        as={inputType}
        type="text"
        name={`${baseInputName}[value]`}
        onChange={(e) => handleResponseChange(e.currentTarget.value)}
        required={question.required}
        defaultValue={response ? response.value : ''}
        maxLength={question.max_characters}
      />
    </Form.Group>
  )
}

export default QuestionInputText
