import React, { useState, useEffect } from 'react'
import { EventWindowCalculation } from '@interfaces/events'
import FormDatetimePicker from '@ke-forms/FormDatetimePicker'

interface Labels {
  start: string
  end: string
}

interface WindowOptionsFieldsProps {
  labels: Labels
  fieldNames: Labels
  windowDates: EventWindowCalculation
  editable: boolean
}

const WindowOptionsFields: React.FC<WindowOptionsFieldsProps> = ({
  labels,
  fieldNames,
  windowDates,
  editable,
}) => {
  const [startAt, setStartAt] = useState(windowDates.startAt)
  const [endAt, setEndAt] = useState(windowDates.endAt)

  useEffect(() => {
    if (!editable) {
      setStartAt(windowDates.startAt)
      setEndAt(windowDates.endAt)
    }
  }, [windowDates])

  return (
    <div className="form-row">
      <FormDatetimePicker
        disabled={!editable}
        label={labels.start}
        placeholder={labels.start}
        value={startAt}
        onChange={(dates): void => setStartAt(dates[0])}
        className="col-lg-6"
        name={`program_event[${fieldNames.start}]`}
      />

      <FormDatetimePicker
        disabled={!editable}
        label={labels.end}
        placeholder={labels.end}
        value={endAt}
        onChange={(dates): void => setEndAt(dates[0])}
        className="col-lg-6"
        name={`program_event[${fieldNames.end}]`}
      />
    </div>
  )
}

export default WindowOptionsFields
