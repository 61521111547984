import { useLocalStore } from 'mobx-react-lite'
import { compareDesc, parseISO } from 'date-fns'
import { parseBooleanString } from '@utils/strings'

const roundDateToQuarterHours = (date): string => {
  date = new Date(date)
  date.setMinutes(Math.round(date.getMinutes() / 15) * 15)
  date.setSeconds(0)

  return date.toISOString()
}

const beginningOfDay = (date): string => {
  if (!date) {
    return null
  }

  date = new Date(date)
  date.setHours(0, 0, 0)
  return date.toISOString()
}

export const useTimeAndDateStore = (
  startAt: string,
  endAt: string,
  detailValue: string,
  computeValue: boolean,
  noHours: boolean
) => {
  const date = beginningOfDay(startAt)
  startAt = startAt ? roundDateToQuarterHours(startAt) : null
  endAt = endAt ? roundDateToQuarterHours(endAt) : null
  noHours = parseBooleanString(noHours)

  return useLocalStore(() => ({
    detailValue: !!detailValue ? parseFloat(detailValue) : 0.0,
    date: date,
    startAt: startAt,
    endAt: endAt,
    noHours: noHours,
    timestamp: new Date().toISOString(),

    get valueError(): string {
      if (
        (computeValue && parseInt(this.computedValue) < 0) ||
        (!computeValue && parseInt(this.detailValue) < 0)
      ) {
        return 'Hours must be greater than or equal to 0'
      } else {
        return null
      }
    },

    get computedValue(): string {
      if (this.noHours) {
        return '0'
      }
      if (!this.startAt || !this.endAt) {
        return '0'
      }

      const timeDiff =
        compareDesc(parseISO(this.endAt), parseISO(this.startAt)) == -1
          ? Math.abs(parseISO(this.endAt).getTime() - parseISO(this.startAt).getTime())
          : 0
      const minutes = Math.floor(timeDiff / 60000)
      return Math.abs(minutes / 60).toFixed(2)
    },

    setNoHours(noHoursChecked): boolean {
      if (noHoursChecked) {
        this.date = null
        this.startAt = null
        this.endAt = null
      }

      this.noHours = noHoursChecked
      return this.noHours
    },

    setStartAt(value): string {
      const newVal = this.addTimeToDay(this.date, value)

      if (this.startAt === newVal) {
        this.forceUpdate()
      }

      this.startAt = newVal
      return this.startAt
    },

    setEndAt(value): string {
      const newVal = this.addTimeToDay(this.date, value)

      if (this.endAt === newVal) {
        this.forceUpdate()
      }

      this.endAt = newVal
      return this.endAt
    },

    setDate(value): string {
      if (value) {
        const date: Date = new Date(value)
        date.setHours(0, 0, 0)

        this.date = date
        this.startAt = this.addTimeToDay(date, this.startAt)
        this.endAt = this.addTimeToDay(date, this.endAt)
      } else {
        this.date = null
        this.startAt = null
        this.endAt = null
      }
      return this.date.toISOString()
    },

    setValue(value): number {
      this.detailValue = value
      return this.detailValue
    },

    addTimeToDay(date, dateTime): string {
      if (!date && !dateTime) {
        return null
      } else if (!date) {
        date = dateTime
      } else if (!dateTime) {
        dateTime = date
      }

      date = new Date(date)
      dateTime = new Date(dateTime)
      date.setHours(dateTime.getHours())
      date.setMinutes(Math.round(dateTime.getMinutes() / 15) * 15)
      date.setSeconds(0)

      return date.toISOString()
    },

    // Due to the rounding logic, some "change" events revert back to
    // their previous values.  This causes child components to become stale,
    // since their props technically don't change
    forceUpdate(): void {
      this.timestamp = new Date().toISOString()
    },
  }))
}
