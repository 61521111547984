import React from 'react'
import { useObserver } from 'mobx-react-lite'
import Flatpickr from 'react-flatpickr'
import l10n from 'flatpickr/dist/l10n'
import { onKeyActivate } from '@utils/onKeyActivate'
import { EventDetailQuestion } from '@interfaces/events'
import { useTimeAndDateStore } from './useTimeAndDateStore'

const { I18n } = window

type Props = {
  name: string
  value: string
  question: EventDetailQuestion
  start_at: string
  end_at: string
  no_hours?: boolean
}

const TimeAndTravel: React.FC<Props> = ({ name, value, question, start_at, end_at, no_hours }) => {
  const {
    time_code: timeCode,
    collect_time_log: collectTimeLog,
    question: label,
    required,
  } = question

  const store = useTimeAndDateStore(start_at, end_at, value, collectTimeLog, no_hours)

  const timeFieldOptions = {
    noCalendar: true,
    enableTime: true,
    minuteIncrement: 15,
    dateFormat: 'h:i K',
  }
  const hasTimes = store.startAt && store.endAt
  const isEndBeforeStart: boolean = collectTimeLog && hasTimes && store.startAt >= store.endAt

  return useObserver(() => (
    <fieldset>
      <legend className="h6">{label}</legend>
      <input type="hidden" name={`${name}[start_at]`} value={store.startAt || ''} />
      <input type="hidden" name={`${name}[end_at]`} value={store.endAt || ''} />
      <input type="hidden" name={`${name}[no_hours]`} value={store.noHours.toString()} />

      <div className="form-row">
        <div className="form-group col-12 col-md-4 col-lg-3">
          <label className="form-label">
            {I18n.t('common.date') + `${required ? ' *' : ''}`}
            <div className="input-group">
              <Flatpickr
                className="form-control form-control-lg bg-white"
                value={store.noHours ? '' : store.date}
                onChange={(dates) => store.setDate(dates[0])}
                required={required}
                disabled={store.noHours}
                options={{
                  maxDate: Date.now(),
                  locale: l10n[I18n.language],
                  dateFormat: I18n.t('date.formats.flatpickr_date'),
                }}
              />
              <div className="input-group-append">
                <span className="input-group-text mdi mdi-calendar" aria-hidden="true"></span>
              </div>
            </div>
          </label>
        </div>

        {collectTimeLog ? (
          <>
            <div className="form-group col-6 col-sm-4 col-md-3 col-lg-2">
              <label className="form-label">
                {I18n.t('common.start_time') + `${required ? ' *' : ''}`}
                <Flatpickr
                  id="start_at"
                  className="form-control form-control-lg bg-white"
                  options={timeFieldOptions}
                  data-enable-time
                  value={store.noHours ? '' : store.startAt}
                  onChange={(dates) => store.setStartAt(dates[0])}
                  disabled={store.noHours}
                />
              </label>
            </div>
            <div className="form-group col-6 col-sm-4 col-md-3 col-lg-2">
              <label className="form-label">
                {I18n.t('common.end_time') + `${required ? ' *' : ''}`}
                <Flatpickr
                  id="end_at"
                  className="form-control form-control-lg bg-white"
                  options={timeFieldOptions}
                  data-enable-time
                  value={store.noHours ? '' : store.endAt}
                  onChange={(dates) => store.setEndAt(dates[0])}
                  disabled={store.noHours}
                />
              </label>
            </div>
            <div className="form-group col-12 col-sm-4 col-md-2">
              <label className="form-label">
                {`${I18n.t('common.total')} (${I18n.t('common.hours_abbr').toLowerCase()})`}
                <output
                  className="form-control form-control-lg bg-light border-0 shadow-none font-weight-medium"
                  name={`${name}[value]`}
                  htmlFor="start_at end_at">
                  {store.computedValue}
                </output>
              </label>
              <input type="hidden" value={store.computedValue} name={`${name}[value]`} />
            </div>
          </>
        ) : (
          <>
            {timeCode === 'mileage' ? (
              <div className="form-group col-6 col-sm-4 col-md-3 col-lg-2">
                <label className="form-label">
                  {`${I18n.t('common.total')} (${I18n.t('common.in').toLowerCase()} ${I18n.t(
                    'distances.mile_equivalent'
                  ).toLowerCase()})${required ? ' *' : ''}`}
                  <input
                    type="number"
                    name={`${name}[value]`}
                    value={store.detailValue}
                    className="form-control form-control-lg"
                    step="1"
                    min="1"
                    max="999"
                    onChange={(e): number => store.setValue(e.target.value)}
                  />
                </label>
              </div>
            ) : (
              <div className="form-group col-6 col-sm-4 col-md-3 col-lg-2">
                <label className="form-label">
                  {`${I18n.t('common.total')} (${I18n.t('common.in').toLowerCase()} ${I18n.t(
                    'common.hours_abbr'
                  ).toLowerCase()})${required ? ' *' : ''}`}
                  <input
                    type="number"
                    name={`${name}[value]`}
                    value={store.detailValue}
                    className="form-control form-control-lg"
                    step="0.01"
                    min="0"
                    max="24"
                    onChange={(e): number => store.setValue(e.target.value)}
                  />
                </label>
              </div>
            )}
          </>
        )}
      </div>
      {collectTimeLog && (
        <label className="bg-gray-100 p-2">
          <input
            type="checkbox"
            id="noHours"
            className="mr-2"
            onChange={() => store.setNoHours(!store.noHours)}
            onKeyDown={onKeyActivate('click')}
            checked={store.noHours || false}
          />
          {I18n.t('views.events.no_hours')}
        </label>
      )}
      {isEndBeforeStart && !store.noHours && (
        <span className="text-red-600 text-center ml-5">
          {I18n.t('views.events.ir_end_before_start')}
        </span>
      )}
    </fieldset>
  ))
}

export default TimeAndTravel
