import React, { useState } from 'react'
import { Row, Col } from 'react-bootstrap'

import {
  EventsProgram,
  EventActivity,
  EventProduct,
  ProgramLocation,
  ProgramEvent,
  ProgramProduct,
  Instructor,
} from '@interfaces/events'
import { Locale } from '@interfaces/global'
import { parseDateString } from '@utils/strings'
import GeneralInformationSection from './GeneralInformationSection'
import InstructorsSection from './InstructorsSection'
import EventActivitiesSection from './EventActivitiesSection'
import EventAgeRequirementsSection from './EventAgeRequirementsSection'
import EventDetailsSection from './EventDetailsSection'
import EventPriceSection from './EventPriceSection'
import EventWaitlistSection from './EventWaitlistSection'
import LocationSection from './LocationSection'
import NotificationSection from './NotificationSection'
import WindowOptionsSection from './WindowOptionsSection'

import {
  defaultEventName,
  calculateMaxCapacity,
  calculateWindowDates,
  initializeEventProduct,
  isWaitlistSupported,
  canChangeAgeSettings,
  getDefaultAgeRestrictions,
  buildEventProducts,
} from './EventFormUtils'

import { isValid } from './EventFormValidator'

const { I18n } = window

interface EventFormProps {
  locales: Locale[]
  programs: EventsProgram[]
  programLocation: ProgramLocation
  eventActivities: EventActivity[]
  programEvent: ProgramEvent
  visibilities: string[][]
  capacity: number
  aboutThisEvent: string
  registrationConfirmationMessage: string
  waitlist: number
  registrationNotifications: boolean
  cancellationNotifications: boolean
  materialOrderShippingLocation: string
  minAgeDateCalculationOptions: string[][]
  maxAgeDateCalculationOptions: string[][]
  instructors: Instructor[]
  allowAllEdits: boolean
  allowPriceEdits: boolean
  cancelPath: string
  copyingEvent: boolean
  paidEventsEnabled: boolean
}

const defaultLocale = {
  id: null,
  iso_code: null,
  name: null,
  locale_code: null,
  language_code: null,
}

const EventForm: React.FC<EventFormProps> = ({
  locales,
  programs,
  programLocation,
  programEvent,
  eventActivities: providedActivities,
  visibilities,
  capacity,
  aboutThisEvent,
  registrationConfirmationMessage,
  waitlist,
  registrationNotifications,
  cancellationNotifications,
  materialOrderShippingLocation,
  minAgeDateCalculationOptions,
  maxAgeDateCalculationOptions,
  instructors,
  allowAllEdits,
  allowPriceEdits,
  cancelPath,
  copyingEvent,
  paidEventsEnabled,
}) => {
  const instructorEventForm = document.getElementById('event-form') as HTMLFormElement
  const [formSubmitted, setFormSubmitted] = useState(false)
  const program = programEvent.events_program_id
    ? programs.find((p) => parseInt(p.id) === parseInt(programEvent.events_program_id))
    : programs[0]
  const [selectedProgram, setSelectedProgram] = useState(program)
  const ageSettings = getDefaultAgeRestrictions(programEvent, selectedProgram)
  const materialOrderShippingLocationDisabled = programEvent.status == 'active'
  const editingEvent = !!programEvent.id
  const initialWaitlist =
    editingEvent || copyingEvent
      ? waitlist
      : calculateMaxCapacity(selectedProgram, programLocation.capacity)
  const displayPricingSection =
    paidEventsEnabled &&
    selectedProgram.payment_settings.payment_mode == 'enabled' &&
    allowPriceEdits

  const [data, setData] = useState({
    eventActivities: providedActivities,
    eventLocale: defaultLocale,
    eventName: defaultEventName(program, programEvent.event_name),
    aboutThisEvent: aboutThisEvent || '',
    registrationConfirmationMessage: registrationConfirmationMessage || '',
    capacity: capacity || programLocation.capacity,
    instructors: instructors,
    eventDateWindows: calculateWindowDates(program, programEvent, providedActivities),
    eventProducts: buildEventProducts(programEvent, program),
    waitlistEnabled: initialWaitlist > 0,
    waitlist: initialWaitlist,
    materialOrderShippingLocation: materialOrderShippingLocation || '',
    ...ageSettings,
  })

  const onProgramChangeEvent = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    const programId = e.target.value
    const program = programs.find((p) => parseInt(p.id) === parseInt(programId))
    const eventDateWindows = calculateWindowDates(program, programEvent, data.eventActivities)
    const ageSettings = getDefaultAgeRestrictions(programEvent, selectedProgram)

    setSelectedProgram(program)
    setData({
      ...data,
      ...ageSettings,
      eventDateWindows,
      eventName: defaultEventName(program, programEvent.event_name),
      eventProducts: buildEventProducts(programEvent, program, true),
      waitlist: calculateMaxCapacity(program, data.capacity),
      waitlistEnabled: calculateMaxCapacity(program, data.capacity) > 0,
    })
  }

  const onEventLocaleChangeEvent = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    const localeId = e.target.value
    const eventLocale = locales.find((l: Locale) => l.id == parseInt(localeId))
    setData({
      ...data,
      eventLocale,
    })
  }

  const onEventNameChangeEvent = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setData({
      ...data,
      eventName: e.target.value,
    })
  }

  const onCapacityChangeEvent = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setData({
      ...data,
      capacity: parseInt(e.target.value, 10) || 0,
    })
  }

  const setCapacity = (capacity: number): void => {
    setData({ ...data, capacity: capacity })
  }

  const onWaitlistChangeEvent = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setData({
      ...data,
      waitlist: parseInt(e.target.value, 10) || 0,
    })
  }

  const onWaitlistEnabledChangeEvent = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setData({
      ...data,
      waitlistEnabled: !data.waitlistEnabled,
      waitlist: e.target.checked ? calculateMaxCapacity(selectedProgram, data.capacity) : 0,
    })
  }

  const onAboutChangeEvent = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setData({
      ...data,
      aboutThisEvent: e.target.value,
    })
  }

  const onMaterialOrderShippingLocation = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setData({
      ...data,
      materialOrderShippingLocation: e.target.value,
    })
  }

  const onRegistrationChangeEvent = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setData({
      ...data,
      registrationConfirmationMessage: e.target.value,
    })
  }

  const onAgeRestrictionChangeEvent = (
    propertyKey: string,
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setData({
      ...data,
      [propertyKey]: e.target.value,
    })
  }

  const onAddEventActivity = (eventActivity: EventActivity): void => {
    const eventActivities = [...data.eventActivities, eventActivity].sort(
      (a: EventActivity, b: EventActivity) => {
        const aStart = parseDateString(a.start_at)
        const bStart = parseDateString(b.start_at)
        if (aStart > bStart) return 1
        if (bStart > aStart) return -1

        return 0
      }
    )

    const eventDateWindows = calculateWindowDates(selectedProgram, programEvent, eventActivities)
    setData({ ...data, eventDateWindows, eventActivities })
  }

  const onRemoveEventActivity = (idx: number): void => {
    const eventActivities = data.eventActivities.slice(0)
    if (eventActivities[idx].id) {
      eventActivities[idx]._destroy = true
    } else {
      eventActivities.splice(idx, 1)
    }

    const eventDateWindows = calculateWindowDates(selectedProgram, programEvent, eventActivities)
    setData({ ...data, eventDateWindows, eventActivities })
  }

  const onAddInstructor = (instructor: Instructor): void => {
    const newInstructorList = [...data.instructors, instructor]
    setData({ ...data, instructors: newInstructorList })
  }

  const onRemoveInstructor = (instructor: Instructor): void => {
    let newInstructorList = [...data.instructors]
    if (instructor.id) {
      // instructor record is persisted, set the _destroy attribute
      const removedInstructor = newInstructorList.find(
        (i) => i.customer_id == instructor.customer_id
      )
      removedInstructor._destroy = true
    } else {
      // remove non-persisted instructor from the list
      newInstructorList = newInstructorList.filter((i) => i.customer_id !== instructor.customer_id)
    }
    setData({ ...data, instructors: newInstructorList })
  }

  const onAddEventProduct = (programProduct: ProgramProduct): void => {
    const newEventProducts = [...data.eventProducts, initializeEventProduct(programProduct)]
    setData({ ...data, eventProducts: newEventProducts })
  }

  const onRemoveEventProduct = (eventProduct: EventProduct): void => {
    let newEventProducts = [...data.eventProducts]
    if (eventProduct.id) {
      // product record is persisted, set the _destroy attribute
      const removedEventProduct = newEventProducts.find(
        (p) => p.program_product_id == eventProduct.program_product_id
      )
      removedEventProduct._destroy = true
    } else {
      // remove non-persisted product from the list
      newEventProducts = newEventProducts.filter(
        (i) => i.program_product_id !== eventProduct.program_product_id
      )
    }
    setData({ ...data, eventProducts: newEventProducts })
  }

  const onEventPriceUpdate = (eventProduct: EventProduct, newPrice: number) => {
    const newEventProducts = [...data.eventProducts]
    newEventProducts.map((product) => {
      if (product.program_product_id == eventProduct.program_product_id) {
        product.price_cents = newPrice
      }
    })
    setData({ ...data, eventProducts: newEventProducts })
  }

  const formDisabled = !isValid(data, selectedProgram) || formSubmitted

  return (
    <React.Fragment>
      {allowAllEdits ? (
        <>
          <GeneralInformationSection
            locales={locales}
            eventLocale={data.eventLocale}
            onProgramChangeEvent={onProgramChangeEvent}
            onEventNameChangeEvent={onEventNameChangeEvent}
            onEventLocaleChangeEvent={onEventLocaleChangeEvent}
            programs={programs}
            program={selectedProgram}
            value={data.eventName}
          />

          <InstructorsSection
            instructors={data.instructors}
            instructorDetailQuestions={selectedProgram.instructor_detail_questions}
            program={selectedProgram}
            onAddInstructor={onAddInstructor}
            onRemoveInstructor={onRemoveInstructor}
          />
        </>
      ) : null}

      <LocationSection
        programLocation={programLocation}
        onCapacityChange={onCapacityChangeEvent}
        capacity={data.capacity}
        setCapacity={setCapacity}
        materialOrderShippingLocation={data.materialOrderShippingLocation}
        onMaterialOrderShippingLocation={onMaterialOrderShippingLocation}
        materialOrderShippingLocationDisabled={materialOrderShippingLocationDisabled}
      />

      {allowAllEdits ? (
        <>
          <EventActivitiesSection
            eventActivities={data.eventActivities}
            onAddEventActivity={onAddEventActivity}
            onRemoveEventActivity={onRemoveEventActivity}
          />

          {canChangeAgeSettings(programEvent, selectedProgram) ? (
            <EventAgeRequirementsSection
              program={selectedProgram}
              onAgeRestrictionChangeEvent={onAgeRestrictionChangeEvent}
              minAgeDateCalculationOptions={minAgeDateCalculationOptions}
              maxAgeDateCalculationOptions={maxAgeDateCalculationOptions}
              minAge={data.minAge}
              minAgeDateCalculation={data.minAgeDateCalculation}
              minAgeDisplayText={data.minAgeDisplayText}
              maxAge={data.maxAge}
              maxAgeDateCalculation={data.maxAgeDateCalculation}
              maxAgeDisplayText={data.maxAgeDisplayText}
            />
          ) : null}
        </>
      ) : null}

      {displayPricingSection ? (
        <EventPriceSection
          eventProducts={data.eventProducts}
          programProducts={selectedProgram.program_products}
          onAddEventProduct={onAddEventProduct}
          onRemoveEventProduct={onRemoveEventProduct}
          onEventPriceUpdate={onEventPriceUpdate}
        />
      ) : null}

      <EventDetailsSection
        onAboutChangeEvent={onAboutChangeEvent}
        onRegistrationChangeEvent={onRegistrationChangeEvent}
        aboutThisEvent={data.aboutThisEvent}
        registrationConfirmationMessage={data.registrationConfirmationMessage}
      />

      <EventWaitlistSection
        value={data.waitlist}
        maxCapacity={calculateMaxCapacity(selectedProgram, data.capacity)}
        disabled={!isWaitlistSupported(selectedProgram)}
        waitlistEnabled={data.waitlistEnabled}
        onWaitlistEnabled={onWaitlistEnabledChangeEvent}
        onChange={onWaitlistChangeEvent}
      />

      {allowAllEdits && data.eventDateWindows && (
        <WindowOptionsSection
          windowDates={data.eventDateWindows}
          program={selectedProgram}
          visibilities={visibilities}
        />
      )}

      <NotificationSection
        registrationNotifications={registrationNotifications}
        cancellationNotifications={cancellationNotifications}
      />

      <Row>
        <Col md="auto" className="px-2 my-1 my-md-0 ml-auto">
          <a href={cancelPath} className="btn btn-outline-primary btn-sm-block">
            {I18n.t('common.cancel')}
          </a>
        </Col>
        <Col md="auto" className="order-first order-md-last px-2 my-1 my-md-0">
          <button
            disabled={formDisabled}
            onClick={(e) => {
              setFormSubmitted(true)
              instructorEventForm.submit()
            }}
            className="btn btn-primary btn-sm-block"
          >
            {editingEvent ? I18n.t('common.save_changes') : I18n.t('views.events.create')}
          </button>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default EventForm
