import React from 'react'
import Form from 'react-bootstrap/Form'

import { EventsProgram } from '@interfaces/events'

const { I18n } = window

interface EventNameSelectFieldProps {
  program: EventsProgram
  value: string
}

const EventNameSelectField: React.FC<EventNameSelectFieldProps> = ({ program, value }) => (
  <Form.Group>
    <Form.Label>{I18n.t('views.events.select_event_type')}</Form.Label>
    <Form.Control as="select" name="program_event[event_name]" defaultValue={value || ''}>
      {program.event_name_options.map((name, index) => (
        <option key={`event_name-${index}`}>{name}</option>
      ))}
    </Form.Control>
  </Form.Group>
)

export default EventNameSelectField
