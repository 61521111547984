import React from 'react'
import { Form } from 'react-bootstrap'
import { Question, CustomerSurveyQuestionResponse } from '@interfaces/surveys'

interface QuestionInputNumberProps {
  question: Question
  baseInputName: string
  controlId: string
  response: CustomerSurveyQuestionResponse
  handleResponseChange(value: string): void
  multiInput?: boolean
}

const QuestionInputNumber: React.FC<QuestionInputNumberProps> = ({
  question,
  baseInputName,
  controlId,
  response,
  handleResponseChange,
  multiInput,
}) => {
  const labelClass = multiInput ? 'font-size-sm' : 'font-weight-medium'

  return (
    <Form.Group controlId={controlId}>
      <Form.Label className={labelClass}>
        {question.question} {question.required || multiInput ? '*' : null}
      </Form.Label>
      <Form.Control
        type="number"
        name={`${baseInputName}[value]`}
        required={question.required}
        min={question.number_min}
        max={question.number_max}
        onChange={(e) => handleResponseChange(e.currentTarget.value)}
        defaultValue={response ? response.value : ''}
        step="0.0001"
      />
    </Form.Group>
  )
}

export default QuestionInputNumber
