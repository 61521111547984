window.addEventListener('turbolinks:load', function() {
  const enableDisablePin = document.getElementById('instructor_disable_pin') as HTMLInputElement

  if (enableDisablePin) {
    enableDisablePin.addEventListener("click", function () {
      const disabled = !enableDisablePin.checked
      const enableEnterAnswer = document.getElementById('enable_enter_answer') as HTMLInputElement
      enableEnterAnswer.disabled = disabled

      for (const element of Array.from(document.getElementsByClassName("disable_pin_hidden"))) {
        (element as HTMLElement).classList.toggle('d-none', disabled)
      }
    })
  }

})
