import React, { useState } from 'react'
import { Alert } from 'react-bootstrap'
import Portal from '@components/shared/Portal'
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js'

const { I18n } = window

interface StripePaymentElementProps {
  capacityUrl: string
  registrationCount: number
  successUrl: string
}

const StripePaymentElement: React.FC<StripePaymentElementProps> = ({
  capacityUrl,
  registrationCount,
  successUrl,
}) => {
  const stripe = useStripe()
  const elements = useElements()

  const [errorMessage, setErrorMessage] = useState(null)
  const [submitting, setSubmitting] = useState(false)
  const [paymentInfoComplete, setPaymentInfoComplete] = useState(false)

  const completePaymentButtonId = 'complete-payment-button-portal'
  const formId = 'stripe-payment-form'
  const capacityCheckUrl = `${capacityUrl}?registration_count=${registrationCount}`

  const checkPaymentInfoCompletion = (event): void => {
    setPaymentInfoComplete(event.complete)
  }

  const stripeNotLoaded = (): boolean => {
    return !stripe || !elements
  }

  const checkCapacity = async () => {
    return fetch(capacityCheckUrl).then((response) => response.json())
  }

  const handleSubmit = async (event) => {
    // Prevent Page Refresh
    event.preventDefault()
    setSubmitting(true)
    setErrorMessage(null)

    // Verify Stripe is loaded
    if (stripeNotLoaded()) {
      setSubmitting(false)
      return
    }

    // Check Event Capacity
    const { error } = await checkCapacity().then((json) => {
      if (json.error) {
        return { error: { message: json.error } }
      } else {
        // Submit Payment
        return stripe.confirmPayment({
          // @ts-ignore
          elements,
          confirmParams: {
            return_url: successUrl,
          },
        })
      }
    })

    if (error) {
      setErrorMessage(error.message)
    }
    setSubmitting(false)
  }

  return (
    <React.Fragment>
      <form id={formId} onSubmit={handleSubmit}>
        {errorMessage ? <Alert variant="danger">{errorMessage}</Alert> : null}
        <PaymentElement onChange={checkPaymentInfoCompletion} />
      </form>

      <Portal mountId={completePaymentButtonId}>
        <button
          name="commit"
          type="submit"
          form={formId}
          className="btn btn-primary btn-block"
          disabled={!paymentInfoComplete || submitting}
        >
          {submitting
            ? `${I18n.t('views.orders.processing')}...`
            : I18n.t('views.events.complete_payment')}
        </button>
      </Portal>
    </React.Fragment>
  )
}

export default StripePaymentElement
