import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'

// Used example from https://blog.logrocket.com/learn-react-portals-by-example/
// Portals allow you to insert & control React components in the external DOM

interface PortalProps {
  mountId: string
  tagName?: string
  children?: React.ReactNode
}

const Portal: React.FC<PortalProps> = ({ mountId, tagName = 'div', children }) => {
  const mount = document.getElementById(mountId)
  const el = document.createElement(tagName)

  useEffect(() => {
    // clear out any lingering child elements
    mount.innerHTML = ''
    mount.appendChild(el)
    return (): HTMLElement => mount.removeChild(el)
  }, [el, mount])

  return ReactDOM.createPortal(children, el)
}

export default Portal
