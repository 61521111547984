import React, { Fragment, useState } from 'react'
import { Form, Button } from 'react-bootstrap'
import classNames from 'classnames'
import Portal from '@components/shared/Portal'
import FeatherIcon from '@components/shared/FeatherIcon'
import EventPoliciesConfirmed from './EventPoliciesConfirmed'
import WaiverConfirmation from './WaiverConfirmation'

const { I18n } = window

const ParticipantRegistration: React.FC<any> = ({
  accounts,
  current_customer_id,
  selected_customer_id,
  registration_customer_id,
  errors,
  waiver,
  waiver_settings,
  registration_waivers,
  can_add_participants,
}) => {
  const addParticipantPortalDiv = document.getElementById('add-another-participant-portal')
  const previousSaveAttempt = !!errors
  const primaryAccount = accounts.find((a) => a.id === current_customer_id)

  // Use the selected customer, otherwise
  // null if primary customer already registered
  // null if form has already been saved
  const defaultCustomerId =
    selected_customer_id ||
    (previousSaveAttempt || primaryAccount.registered ? null : current_customer_id)

  const [selected, setSelected] = useState(defaultCustomerId)
  const selectedAccount = selected ? accounts.find((a) => a.id === selected) : null

  const primarySelected = selected === current_customer_id
  const linkedAccount = accounts.find((a) => a.id === selected && a.id !== current_customer_id)
  const primaryAccountIneligibile = primarySelected && primaryAccount.age_eligible.message
  const linkedAccountIneligibile = linkedAccount && linkedAccount.age_eligible.message

  // Filter accounts that have already registered unless editing
  // an existing registration
  const linkedAccountOptions = accounts.filter(
    (acct) =>
      (!acct.registered || registration_customer_id == acct.id) && acct.id !== current_customer_id
  )

  const submitDisabled = !(linkedAccount || primaryAccount).age_eligible.is_eligible || !selected
  const showWaiver = waiver && waiver_settings.waiver_enforcement === 'enforce'

  return (
    <Fragment>
      <Form.Group className="mb-0">
        <fieldset>
          <legend className="col-form-label font-weight-medium pt-0">
            {I18n.t('views.events.registration_who')}
          </legend>

          <div
            className={classNames('p-2 rounded', {
              'bg-gray-100': !primarySelected,
              'bg-green-100': primarySelected && !primaryAccountIneligibile,
              'bg-red-100': primarySelected && primaryAccountIneligibile,
            })}>
            <Form.Check
              inline
              type="radio"
              name="primary"
              id="primary-myself"
              label={I18n.t('views.events.myself_primary_account')}
              disabled={primaryAccount.registered && registration_customer_id != primaryAccount.id}
              onClick={(): void => setSelected(current_customer_id)}
              defaultChecked={primarySelected}
            />

            {primarySelected ? (
              <input
                type="hidden"
                name="event_registration[customer_id]"
                value={current_customer_id}
              />
            ) : null}
          </div>

          {primaryAccountIneligibile && (
            <div className="text-danger font-size-sm mt-1">
              {I18n.t('views.events.registration_age_eligible')}
            </div>
          )}

          <div
            className={classNames('p-2 mt-1 rounded-top', {
              'bg-gray-100': primarySelected,
              'bg-green-100': !primarySelected && !linkedAccountIneligibile,
              'bg-red-100': !primarySelected && linkedAccountIneligibile,
            })}>
            <Form.Check
              type="radio"
              name="primary"
              id="primary-linked"
              label={I18n.t('views.events.linked_account')}
              disabled={linkedAccountOptions.length === 0}
              onClick={(): void => setSelected(null)}
              defaultChecked={!primarySelected}
            />
          </div>
        </fieldset>
      </Form.Group>

      <Form.Group
        className={classNames('px-2 pb-2 rounded-bottom', {
          'bg-gray-100': primarySelected,
          'bg-green-100': !primarySelected && !linkedAccountIneligibile,
          'bg-red-100': !primarySelected && linkedAccountIneligibile,
        })}>
        <Form.Label className="sr-only" htmlFor="select-linked-account">
          Linked Account
        </Form.Label>
        <Form.Control
          isInvalid={
            (previousSaveAttempt && selected === null) ||
            (!primarySelected && linkedAccountIneligibile)
          }
          as="select"
          name="event_registration[customer_id]"
          id="select-linked-account"
          disabled={primarySelected}
          value={selected || ''}
          onChange={(e): void => {
            setSelected(e.currentTarget.value && parseInt(e.currentTarget.value))
          }}
          className="w-auto ml-3">
          <option value={''} key={0}>
            {I18n.t('views.events.select_account')}
          </option>
          {linkedAccountOptions.map(({ id, full_name }) => (
            <option value={id} key={id}>
              {full_name}
            </option>
          ))}
        </Form.Control>
        {linkedAccountIneligibile && (
          <div className="text-danger font-size-sm mt-1">
            {I18n.t('views.events.registration_age_eligible')}
          </div>
        )}

        <Portal mountId="event-registration-actions-portal">
          {showWaiver ? (
            <WaiverConfirmation
              customer={selectedAccount}
              waiver={waiver}
              submitDisabled={submitDisabled}
              registrationWaivers={registration_waivers}
              addingParticipant={false}
              can_add_participants={can_add_participants}
            />
          ) : (
            <Button
              type="submit"
              className="btn btn-primary btn-sm-block"
              disabled={submitDisabled}>
              {registration_customer_id
                ? I18n.t('common.save_and_continue')
                : I18n.t('common.continue')}
            </Button>
          )}
        </Portal>

        {addParticipantPortalDiv ? (
          <Portal mountId="add-another-participant-portal">
            {showWaiver ? (
              <WaiverConfirmation
                customer={selectedAccount}
                waiver={waiver}
                submitDisabled={submitDisabled}
                registrationWaivers={registration_waivers}
                addingParticipant={true}
                can_add_participants={can_add_participants}
              />
            ) : (
              <Button
                name="commit"
                type="submit"
                value="add_participant"
                variant="secondary"
                className="btn btn-secondary btn-sm-block"
                disabled={!can_add_participants}>
                {I18n.t('views.events.add_participant')}
                <FeatherIcon name="plus-circle" className="ml-2" />
              </Button>
            )}
          </Portal>
        ) : null}

        <Portal mountId="policies-confirmed-portal">
          {selectedAccount ? (
            <EventPoliciesConfirmed
              customerName={selectedAccount.full_name}
              primarySelected={primarySelected}
              isInvalid={errors && !!errors.policies_confirmed}
            />
          ) : (
            undefined
          )}
        </Portal>
      </Form.Group>
    </Fragment>
  )
}

export default ParticipantRegistration
