import { AddressProps, SelectOption } from '@interfaces/global'
import React, { Fragment } from 'react'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import AddressFields from '@components/addresses/AddressFields'
import useSetAddressType from '@components/addresses/useSetAddressType'

const { I18n } = window

interface AddressFormProps {
  defaultAddresses: AddressProps[]
  baseName: string
  stateOptions: SelectOption[]
  stateLabel: string
  validated?: boolean
  horizontalForm?: boolean
}

const AddressForm: React.FC<AddressFormProps> = ({
  defaultAddresses,
  baseName,
  stateOptions,
  stateLabel,
  validated,
  horizontalForm,
}) => {
  const [addresses, showBoth, setShowBoth] = useSetAddressType(defaultAddresses)
  const [mailing, physical] = addresses

  return (
    <div className={validated ? 'was-validated' : ''}>
      <Row>
        <Col lg={horizontalForm ? 6 : 12}>
          <fieldset>
            <legend className="text-base text-uppercase mb-3">
              {I18n.t('views.users.address_form.physical_address')}
            </legend>
            <AddressFields
              idx={0}
              address={mailing}
              baseName={baseName}
              stateOptions={stateOptions}
              stateLabel={stateLabel}
              validated={validated}
            />
          </fieldset>

          <Form.Group controlId="physical">
            <Form.Check
              onChange={(e) => setShowBoth(e.target.checked)}
              type="checkbox"
              checked={showBoth}
              label={I18n.t('views.users.address_form.different_mailing')}
            />
          </Form.Group>
        </Col>
        <Col lg={horizontalForm ? 6 : 12}>
          {showBoth && physical && (
            <Fragment>
              <fieldset>
                <legend className="text-base text-uppercase mb-3">
                  {I18n.t('views.users.address_form.alternate_mailing')}
                </legend>
                <AddressFields
                  idx={1}
                  address={physical}
                  baseName={baseName}
                  stateOptions={stateOptions}
                  stateLabel={stateLabel}
                  validated={validated}
                />
              </fieldset>
            </Fragment>
          )}
          {physical && (
            <Fragment>
              <input type="hidden" value={physical.id || ''} name={`${baseName}[1][id]`} />
              <input
                type="hidden"
                value={physical._destroy || ''}
                name={`${baseName}[1][_destroy]`}
              />
            </Fragment>
          )}
        </Col>
      </Row>
    </div>
  )
}

export default AddressForm
