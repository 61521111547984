import React, { useState } from 'react'
import Form from 'react-bootstrap/Form'

import SectionHeader from '@components/SectionHeader'
import { ProgramLocation } from '@interfaces/events'
import ConfirmationModal from '@components/shared/ConfirmationModal'

const { I18n } = window

interface LocationSectionProps {
  onCapacityChange(e: React.ChangeEvent<HTMLInputElement>): void
  setCapacity(capacity: number): void
  onMaterialOrderShippingLocation(e: React.ChangeEvent<HTMLInputElement>): void
  programLocation: ProgramLocation
  capacity: number
  materialOrderShippingLocation: string
  materialOrderShippingLocationDisabled: boolean
}

const LocationSection: React.FC<LocationSectionProps> = ({
  onCapacityChange,
  setCapacity,
  programLocation,
  capacity,
  materialOrderShippingLocation,
  onMaterialOrderShippingLocation,
  materialOrderShippingLocationDisabled,
}) => {
  const [prevCapacity, setPrevCapacity] = useState(capacity)
  const [capacityModalOpen, setCapacityModalOpen] = useState(false)

  const showCapacityModal = (): void => {
    setCapacityModalOpen(capacity > programLocation.capacity)
  }

  const onAcceptCapacityEvent = (): void => {
    setCapacityModalOpen(false)
    setCapacity(capacity)
  }

  const onCancelCapacityEvent = (): void => {
    setCapacityModalOpen(false)
    setCapacity(prevCapacity)
  }

  return (
    <fieldset>
      <SectionHeader text={I18n.t('views.events.location')} />

      <Form.Group controlId="event_location">
        <Form.Label>{I18n.t('views.events.location')}</Form.Label>
        <Form.Control
          className="form-control-lg"
          disabled
          readOnly
          type="text"
          name="program_event[program_location_attributes][name]"
          value={programLocation.name}
        />
      </Form.Group>

      <Form.Group controlId="shipping_address">
        <Form.Label>{I18n.t('views.events.class_materials_shipping')}</Form.Label>
        <Form.Control
          className="form-control-lg"
          disabled={materialOrderShippingLocationDisabled}
          type="text"
          name="program_event[material_order_shipping_location]"
          onChange={onMaterialOrderShippingLocation}
          value={materialOrderShippingLocation}
        />
      </Form.Group>

      <input
        readOnly
        type="hidden"
        value={programLocation.id}
        name="program_event[program_location_id]"
      />

      <Form.Group controlId="event_capacity">
        <Form.Label>
          {I18n.t('views.events.event_capacity')}{' '}
          <sup>
            <span className="mdi mdi-asterisk initialism" aria-hidden="true"></span>
          </sup>
        </Form.Label>
        <Form.Control
          className="form-control-lg"
          required
          type="number"
          name="program_event[capacity]"
          value={`${capacity}`}
          onChange={onCapacityChange}
          onFocus={(): void => setPrevCapacity(capacity)}
          onBlur={showCapacityModal}
        />
      </Form.Group>

      <ConfirmationModal
        modalOpen={capacityModalOpen}
        onSubmitModal={onAcceptCapacityEvent}
        onCancelModal={onCancelCapacityEvent}
        submitButtonText={I18n.t('common.continue')}
        cancelButtonText={I18n.t('common.cancel')}
        modalBodyText={I18n.t('views.events.confirmation_text')}
        modalHeaderText={I18n.t('views.events.event_capacity')}
      />
    </fieldset>
  )
}

export default LocationSection
