import React from 'react'
import { Form, Button, Col } from 'react-bootstrap'
import { Instructor, EventsProgram } from '@interfaces/events'

import SectionHeader from '@components/SectionHeader'
import SearchInstructors from './SearchInstructors'

const { I18n } = window

interface InstructorsSectionProps {
  instructors: Instructor[]
  instructorDetailQuestions
  program: EventsProgram
  onAddInstructor
  onRemoveInstructor
}

const InstructorsSection: React.FC<InstructorsSectionProps> = ({
  instructors,
  instructorDetailQuestions,
  program,
  onAddInstructor,
  onRemoveInstructor,
}) => {
  return (
    <React.Fragment>
      <SectionHeader text={I18n.t('views.events.assigned_instructors')} />
      <Form.Group controlId="event_instructors">
        {instructors.map((instructor, index) => {
          const instructorInputName = `program_event[instructors_attributes][${index}]`
          if (instructor._destroy) {
            return (
              <React.Fragment key={index}>
                <input type="hidden" name={`${instructorInputName}[id]`} value={instructor.id} />
                <input type="hidden" name={`${instructorInputName}[_destroy]`} value="1" />
              </React.Fragment>
            )
          } else {
            return (
              <React.Fragment key={index}>
                <Form.Row className="mt-2">
                  <Col md={10}>
                    <Form.Row className="ml-0">
                      <input
                        type="hidden"
                        name={`${instructorInputName}[id]`}
                        value={instructor.id}
                      />
                      <input
                        type="hidden"
                        name={`${instructorInputName}[customer_id]`}
                        value={instructor.customer_id}
                      />
                      <input
                        type="hidden"
                        name={`${instructorInputName}[is_primary]`}
                        value={instructor.is_primary ? '1' : '0'}
                      />
                      {/*
                        full_name and email are not persisted -
                        needed for displaying in case the event
                        fails to save or update
                      */}
                      <input
                        type="hidden"
                        name={`${instructorInputName}[full_name]`}
                        value={instructor.full_name}
                      />
                      <input
                        type="hidden"
                        name={`${instructorInputName}[email]`}
                        value={instructor.email}
                      />
                      <Form.Label className="text-weight-bold">{instructor.full_name}</Form.Label>
                      <Form.Label className="overline badge badge-dark text-uppercase ml-1 p-2 font-weight-light">
                        {instructor.is_primary
                          ? I18n.t('views.events.primary')
                          : I18n.t('common.assistant')}
                      </Form.Label>
                    </Form.Row>
                    <Form.Row className="ml-0">
                      <Form.Label className="text-muted">{instructor.email}</Form.Label>
                    </Form.Row>
                  </Col>
                  {!instructor.is_primary && (
                    <Col md={2}>
                      <Button
                        className="w-100"
                        onClick={() => onRemoveInstructor(instructor)}
                        value={instructor.customer_id}>
                        {I18n.t('common.remove')}
                      </Button>
                    </Col>
                  )}
                </Form.Row>
              </React.Fragment>
            )
          }
        })}
        <SearchInstructors
          instructors={instructors}
          instructorDetailQuestions={instructorDetailQuestions}
          program={program}
          onAddInstructor={onAddInstructor}
          immediatelySubmit={false}
        />
      </Form.Group>
    </React.Fragment>
  )
}

export default InstructorsSection
