import React, { FC } from 'react'
import { EventsProgram } from '@app/interfaces/events'
import SearchInstructors from '../../events/EventForm/SearchInstructors'

interface SearchInstructorsFormProps {
  instructors
  program: EventsProgram
  event_id: number
}

const SearchInstructorsForm: FC<SearchInstructorsFormProps> = (props) => {
  const instructors = props.instructors.map((instructor) => {
    return {
      customer_id: instructor.customer_id,
      is_primary: instructor.is_primary,
      full_name: instructor.full_name,
      email: instructor.customer.email_address,
    }
  })

  return (
    <SearchInstructors
      instructors={instructors}
      instructorDetailQuestions={props.program.instructor_detail_questions}
      program={props.program}
      immediatelySubmit
      eventId={props.event_id}
    />
  )
}

export default SearchInstructorsForm
