import React from 'react'
import Form from 'react-bootstrap/Form'

import { EventsProgram } from '@interfaces/events'

const { I18n } = window

interface EventProgramFieldProps {
  onChange(e: React.ChangeEvent<HTMLSelectElement>): void
  programs: EventsProgram[]
  programId: string
}

const EventProgramField: React.FC<EventProgramFieldProps> = ({ programs, onChange, programId }) => {
  return (
    <Form.Group controlId="events_program">
      <Form.Label>{I18n.t('views.events.select_program')}</Form.Label>
      <Form.Control
        as="select"
        name="program_event[events_program_id]"
        onChange={onChange}
        value={programId}>
        {programs.map((p, idx) => (
          <option value={p.id} key={idx}>
            {p.name}
          </option>
        ))}
      </Form.Control>
    </Form.Group>
  )
}

export default EventProgramField
