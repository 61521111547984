import React, { useState } from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { EventsProgram, EventWindowCalculationObject } from '@interfaces/events'
import WindowOptionsFields from './components/WindowOptionsFields'
import SectionHeader from '@components/SectionHeader'

const { I18n } = window

interface WindowOptionsSectionProps {
  windowDates: EventWindowCalculationObject
  program: EventsProgram
  visibilities: string[][]
}

const WindowOptionsSection: React.FC<WindowOptionsSectionProps> = ({
  windowDates,
  program,
  visibilities,
}) => {
  const [editWindowButtonClicked, setEditWindowButtonClicked] = useState(false)
  const instructorOverridable = program.instructor_overridable
  const windowInputsActive =
    instructorOverridable && ((windowDates && windowDates.persisted) || editWindowButtonClicked)

  return (
    <React.Fragment>
      <SectionHeader text={I18n.t('views.events.registration_options')} />
      <WindowOptionsFields
        labels={{
          start: I18n.t('views.events.registration_start'),
          end: I18n.t('views.events.registration_end'),
        }}
        fieldNames={{
          start: 'registration_start_at',
          end: 'registration_end_at',
        }}
        windowDates={windowDates.registration}
        editable={windowInputsActive}
      />

      <SectionHeader text={I18n.t('views.events.visibility_options')} />
      <Form.Group controlId="event_public_visibility">
        <Form.Label>{I18n.t('views.events.public_visibility')}</Form.Label>
        <Form.Control as="select" name="program_event[visibility]">
          {visibilities.map((v, idx) => (
            <option value={v[0]} key={idx}>
              {v[1]}
            </option>
          ))}
        </Form.Control>
      </Form.Group>

      <WindowOptionsFields
        labels={{
          start: I18n.t('views.events.visibility_start'),
          end: I18n.t('views.events.visibility_end'),
        }}
        fieldNames={{
          start: 'visibility_start_at',
          end: 'visibility_end_at',
        }}
        windowDates={windowDates.visibility}
        editable={windowInputsActive}
      />

      <SectionHeader text={I18n.t('views.events.cancellation_options')} />
      <WindowOptionsFields
        labels={{
          start: I18n.t('views.events.cancellation_start'),
          end: I18n.t('views.events.cancellation_end'),
        }}
        fieldNames={{
          start: 'cancellation_start_at',
          end: 'cancellation_end_at',
        }}
        windowDates={windowDates.cancellation}
        editable={windowInputsActive}
      />

      {instructorOverridable && (
        <div className="pt-2 pb-4">
          <Button
            variant="primary"
            block
            disabled={windowInputsActive}
            onClick={(): void => setEditWindowButtonClicked(true)}>
            <span className="mdi mdi-square-edit-outline pr-1" aria-hidden="true"></span>
            {I18n.t('views.events.edit_windows')}
          </Button>
        </div>
      )}
    </React.Fragment>
  )
}

export default WindowOptionsSection
