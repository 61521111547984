import KeForms from '@app/interfaces/KeForms'
import React, { useContext } from 'react'
import Form from 'react-bootstrap/Form'
import { FormContext } from '@ke-forms/FormContext'
import FormSelect from '@ke-forms/FormSelect'

const FormGroup: React.FC<KeForms.Group> = ({
  type = 'text',
  label,
  inputName,
  inputClass,
  required,
  defaultValue,
  autoComplete,
  options = [],
}) => {
  const { baseName, index, validated, errors } = useContext(FormContext)
  const attrErrors = (errors && errors[inputName]) || null
  const defaults: KeForms.InputDefaults = {
    name: `${baseName}[${inputName}]`,
    className: inputClass,
    defaultValue: defaultValue,
    autoComplete: autoComplete,
  }

  if (validated) {
    defaults.isValid = !attrErrors
  }

  if (required) {
    defaults.required = true
  }

  return (
    <Form.Group controlId={`${inputName}_${index}`}>
      <Form.Label>
        {label} {required && <sup><span className="mdi mdi-asterisk initialism" aria-hidden="true"></span></sup>}
      </Form.Label>

      {type !== 'select' && <Form.Control type={type} {...defaults} />}
      {type === 'select' && <FormSelect index={index} options={options} {...defaults} />}

      <Form.Control.Feedback type="invalid">
        {label} {attrErrors && attrErrors.join(', ')}
      </Form.Control.Feedback>
    </Form.Group>
  )
}

export default FormGroup
