import React from 'react'
import { Form } from 'react-bootstrap'
import { Question, CustomerSurveyQuestionResponse } from '@interfaces/surveys'

interface QuestionInputCheckboxProps {
  question: Question
  baseInputName: string
  controlId: string
  response: CustomerSurveyQuestionResponse
  handleResponseChange(value: string): void
  multiInput?: boolean
}

const QuestionInputCheckbox: React.FC<QuestionInputCheckboxProps> = ({
  question,
  baseInputName,
  controlId,
  response,
  handleResponseChange,
  multiInput,
}) => {
  const values = response && response.value ? response.value.split(';;') : []
  const labelClass = multiInput ? 'font-size-sm' : 'font-weight-medium'

  const onCheckboxChange = (question, responseValue): void => {
    const index = values.findIndex((x) => x == responseValue)

    if (index >= 0) {
      values.splice(index, 1)
    } else {
      values.push(responseValue)
    }

    const newValue = values.join(';;')
    handleResponseChange(newValue)
  }

  return (
    <Form.Group controlId={controlId}>
      <Form.Label className={labelClass}>
        {question.question} {question.required || multiInput ? '*' : null}
      </Form.Label>
      {question.question_responses.map((qr, idx) => (
        <Form.Check
          id={`${controlId}-${idx}`}
          key={`${controlId}-${idx}`}
          label={qr.value}
          value={qr.value}
          type="checkbox"
          defaultChecked={values.indexOf(qr.value) >= 0}
          onChange={(): void => onCheckboxChange(question, qr.value)}
        />
      ))}
      <input
        type="hidden"
        name={`${baseInputName}[value]`}
        value={response && response.value ? response.value : ''}
      />
    </Form.Group>
  )
}

export default QuestionInputCheckbox
