import { Waiver, Customer, RegistrationWaivers } from '@interfaces/global'
import React, { useState, useEffect, useRef, Fragment } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import FeatherIcon from '@components/shared/FeatherIcon'

const { I18n } = window

const form = document.getElementById('event_registration_form') as HTMLFormElement

interface WaiverConfirmationProps {
  waiver: Waiver
  customer: Customer
  submitDisabled: boolean
  registrationWaivers: RegistrationWaivers
  addingParticipant: boolean
  can_add_participants: boolean
}

const WaiverConfirmation: React.FC<WaiverConfirmationProps> = ({
  waiver,
  customer,
  submitDisabled,
  registrationWaivers,
  addingParticipant,
  can_add_participants,
}) => {
  const registrationWaiver = registrationWaivers ? registrationWaivers[0] : null
  const customerWaiver = registrationWaiver ? registrationWaiver.customer_waiver : null
  const initialWaiverValues = customerWaiver ? customerWaiver.customer_waiver_responses : []

  const [openModal, setOpenModal] = useState(false)
  const [validated, setValidated] = useState(false)
  const [waiverValues, setWaiverValues] = useState(initialWaiverValues)
  const formElement = useRef(form)

  useEffect(() => {
    const valid = waiver.waiver_questions.every((question) => {
      const waiverValue = waiverValues.find((x) => {
        return x.waiver_question_id === question.id
      })
      return waiverValue && waiverValue.value !== ''
    })
    setValidated(valid)
  }, [waiverValues])

  const onCancel = () => {
    setOpenModal(false)
  }

  const handleSubmit = () => {
    formElement.current.submit()
    setOpenModal(false)
  }

  const updateWaiverValues = (question, value): void => {
    const newWaiverValues = [...waiverValues]
    const updateWaiver = newWaiverValues.find((x) => {
      return x.waiver_question_id === question.id
    })
    if (updateWaiver) {
      updateWaiver.value = value
      setWaiverValues(newWaiverValues)
    } else {
      setWaiverValues([...waiverValues, { waiver_question_id: question.id, value: value }])
    }
  }

  return (
    <Fragment>
      {addingParticipant ? (
        <Button
          variant="secondary"
          className="btn btn-secondary btn-sm-block"
          disabled={!can_add_participants}
          onClick={() => setOpenModal(true)}>
          {I18n.t('views.events.add_participant')}
          <FeatherIcon name="plus-circle" className="ml-2" />
        </Button>
      ) : (
        <Button
          className="btn btn-primary btn-sm-block"
          onClick={() => setOpenModal(true)}
          disabled={submitDisabled}>
          {I18n.t('common.continue')}
        </Button>
      )}
      {openModal ? (
        <Fragment>
          {addingParticipant ? (
            <input type="hidden" name="action_type" value="add_participant" />
          ) : (
            <input type="hidden" name="action_type" value="continue" />
          )}
        </Fragment>
      ) : null}
      <Modal show={openModal} size="lg" scrollable style={{ maxHeight: '750px' }}>
        <Modal.Header>
          <Modal.Title>{waiver.name}</Modal.Title>
          <button
            className="btn ml-auto"
            onClick={() => setOpenModal(false)}
            aria-label="Close Modal">
            <div className="d-flex align-items-center">
              <FeatherIcon name="x-circle" />
            </div>
          </button>
        </Modal.Header>
        <Modal.Body>
          <b>{I18n.t('views.events.registrant')}:&nbsp;</b>
          {customer ? customer.full_name : null}
          <div className="alert alert-warning mt-3">
            <p className="mb-0">{I18n.t('views.events.scroll')}</p>
          </div>
          <div dangerouslySetInnerHTML={{ __html: waiver.content }}></div>
          {waiver.waiver_questions.map((question, i) => {
            const response = waiverValues.find((x) => {
              return x.waiver_question_id === question.id
            })

            return question.input_type === 'checkbox' ? (
              <Form.Group key={i} controlId={`waiver_check_${i}`}>
                <Form.Check
                  onChange={(e) => updateWaiverValues(question, e.target.checked ? 'Yes' : '')}
                  className="bg-gray-100 py-2 mt-3"
                  style={{ paddingLeft: '30px' }}
                  type="checkbox"
                  label={question.question}
                  defaultChecked={response && response.value === 'Yes'}
                  required
                />
              </Form.Group>
            ) : (
              <Form.Group key={i}>
                <Form.Label className="pt-3">{question.question}</Form.Label>
                <Form.Control
                  type="text"
                  required
                  value={response ? response.value : ''}
                  onChange={(e) => {
                    updateWaiverValues(question, e.target.value)
                  }}
                />
              </Form.Group>
            )
          })}
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-secondary btn-sm-block" onClick={onCancel}>
            {I18n.t('common.cancel')}
          </Button>
          <Button
            onClick={handleSubmit}
            className="btn btn-primary btn-sm-block"
            disabled={!validated}>
            {I18n.t('common.submit')}
          </Button>
        </Modal.Footer>
      </Modal>
      {waiverValues.map((value, i) => {
        return (
          <Fragment key={i}>
            <input
              type="hidden"
              value={value.id}
              name={`event_registration[event_registration_customer_waivers_attributes][0][customer_waiver_attributes][customer_waiver_responses_attributes][${i}][id]`}
            />
            <input
              type="hidden"
              value={value.value}
              name={`event_registration[event_registration_customer_waivers_attributes][0][customer_waiver_attributes][customer_waiver_responses_attributes][${i}][value]`}
            />
            <input
              type="hidden"
              value={value.waiver_question_id}
              name={`event_registration[event_registration_customer_waivers_attributes][0][customer_waiver_attributes][customer_waiver_responses_attributes][${i}][waiver_question_id]`}
            />
          </Fragment>
        )
      })}
      {registrationWaiver ? (
        <input
          type="hidden"
          value={registrationWaiver.id}
          name="event_registration[event_registration_customer_waivers_attributes][0][id]"
        />
      ) : null}
      {customerWaiver ? (
        <input
          type="hidden"
          value={customerWaiver.id}
          name="event_registration[event_registration_customer_waivers_attributes][0][customer_waiver_attributes][id]"
        />
      ) : null}
      <input
        type="hidden"
        value={waiver.id}
        name="event_registration[event_registration_customer_waivers_attributes][0][customer_waiver_attributes][waiver_id]"
      />
      <input
        type="hidden"
        value={customer ? customer.id : ''}
        name="event_registration[event_registration_customer_waivers_attributes][0][customer_waiver_attributes][customer_id]"
      />
    </Fragment>
  )
}

export default WaiverConfirmation
