import React from 'react'

interface SectionHeaderProps {
  text: string
}

const SectionHeader: React.FC<SectionHeaderProps> = ({ text }) => (
  <legend className="h6 my-3">
    <span className="overline gray-line-300 text-muted">{text}</span>
  </legend>
)

export default SectionHeader
