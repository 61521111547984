import React from 'react'
import { Modal, Button } from 'react-bootstrap'

interface ConfirmationModalProps {
  modalOpen: boolean
  onSubmitModal(e: React.MouseEvent<HTMLButtonElement>): void
  onCancelModal(e: React.MouseEvent<HTMLButtonElement>): void
  submitButtonText: string
  cancelButtonText: string
  modalBodyText: string
  modalHeaderText: string
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  modalOpen,
  onSubmitModal,
  onCancelModal,
  submitButtonText,
  cancelButtonText,
  modalBodyText,
  modalHeaderText,
}) => (
  <Modal show={modalOpen}>
    <Modal.Header>
      <Modal.Title>{modalHeaderText}</Modal.Title>
    </Modal.Header>
    <Modal.Body>{modalBodyText}</Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={onCancelModal}>
        {cancelButtonText}
      </Button>
      <Button variant="primary" onClick={onSubmitModal}>
        {submitButtonText}
      </Button>
    </Modal.Footer>
  </Modal>
)

export default ConfirmationModal
