import React from 'react'
import Form from 'react-bootstrap/Form'

import { Locale } from '@interfaces/global'

const { I18n } = window

interface EventLanguageSelectFieldProps {
  selected: Locale
  locales: Locale[]
  onChange(e: React.ChangeEvent<HTMLSelectElement>): void
}

const sortedLocales = (locales) => {
  return locales.sort((a, b) => {
    if (a.language_code > b.language_code) {
      return 1
    } else if (b.language_code > a.language_code) {
      return -1
    } else {
      return 0
    }
  })
}

const EventLanguageSelectField: React.FC<EventLanguageSelectFieldProps> = ({
  selected,
  onChange,
  locales,
}) => (
  <Form.Group>
    <Form.Label>{I18n.t('views.events.select_event_language')}</Form.Label>
    <Form.Control
      value={`${selected.id}`}
      as="select"
      name="program_event[supported_locale_id]"
      onChange={onChange}>
      {sortedLocales(locales).map((locale: Locale | null, index: number) => (
        <option key={`locale-${index}`} value={locale.id}>
          {locale.name}
        </option>
      ))}
    </Form.Control>
  </Form.Group>
)

export default EventLanguageSelectField
