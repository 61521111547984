import React from 'react'
import { useObserver } from 'mobx-react-lite'
import { CustomerSurveyFormContext, createSurveyFormStore } from './surveyFormContext'
import { Question, CustomerSurveyQuestionResponse } from '@interfaces/surveys'
import QuestionResponseInputs from '@components/customers/surveys/QuestionInputs/QuestionResponseInputs'
import { Card } from 'react-bootstrap'

interface CustomerSurveyFormProps {
  questions: Array<Question>
  responses: Array<CustomerSurveyQuestionResponse>
  customerId: number
}

const CustomerSurveyForm: React.FC<CustomerSurveyFormProps> = ({
  questions,
  responses,
  customerId,
}) => {
  const store = createSurveyFormStore(questions, responses, customerId)

  return useObserver(() => (
    <CustomerSurveyFormContext.Provider value={store}>
      {store.questions.map((question, index) => {
        const inputName = `customer_survey[responses_attributes][${index}]`
        const response = store.responses.find((r) => {
          return r.survey_question_id == question.id
        })

        return (
          <React.Fragment key={`survey-question-${index}`}>
            <input
              type="hidden"
              name={`${inputName}[id]`}
              defaultValue={response ? response.id : ''}
            />
            <input
              type="hidden"
              name={`${inputName}[survey_question_id]`}
              defaultValue={question.id}
            />
            <input
              type="hidden"
              name={`${inputName}[skipped]`}
              value={question.skipped ? '1' : '0'}
            />
            {!question.skipped ? (
              <Card className="mb-3">
                <Card.Body>
                  <QuestionResponseInputs
                    question={question}
                    baseInputName={inputName}
                    response={response}
                    controlId={`${question.input_type}-${question.id}`}
                    multiInput={false}
                    onStaticResponseChange={(value) =>
                      store.changeResponseValue(question, response, value)
                    }
                    onActionableResponseChange={(value) =>
                      store.handleOptionSelected(question, response, value, index)
                    }
                  />
                </Card.Body>
              </Card>
            ) : null}
          </React.Fragment>
        )
      })}
    </CustomerSurveyFormContext.Provider>
  ))
}

export default CustomerSurveyForm
