import 'bootstrap.native'

// Function to initialize bootstrap components added to the DOM
window.initBSN = () => {
  const container = document.querySelector('[data-function="turbolinks-autoload"]')
  window.BSN.initCallback(container)
}

// Initialize BSN on each turbolinks load event
document.addEventListener('turbolinks:load', window.initBSN, false)
