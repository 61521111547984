// This an implementation of Stimulus - A modest JavaScript framework
// Reference: https://stimulus.hotwire.dev/
// This has been implemented as a Typescript file as this project utilizes Typescript
// Purpose: Leverage javascript to mask and unmask the password and utilize theme stylized icons
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["unmask", "iconMask", "iconUnmask"]

  unmaskTarget: HTMLButtonElement
  iconMaskTarget: HTMLElement
  iconUnmaskTarget: HTMLElement

  changeMask() {
    if (this.input.type === "password") {
      this.input.type = "text"
      this.iconUnmask.classList.remove('d-none')
      this.iconMask.classList.add('d-none')
    } else {
      this.input.type = "password"
      this.iconMask.classList.remove('d-none')
      this.iconUnmask.classList.add('d-none')
    }
  }

  get iconMask() {
    return this.iconMaskTarget
  }

  get iconUnmask() {
    return this.iconUnmaskTarget
  }

  get input() {
    return this.unmaskTarget
  }
}
