import { CustomerSurveyFormContext } from '@components/customers/surveys/CustomerSurveyForm/surveyFormContext'
import React, { useContext, useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Question, CustomerSurveyQuestionResponse } from '@interfaces/surveys'
import QuestionResponseInputs from '@components/customers/surveys/QuestionInputs/QuestionResponseInputs'

const { I18n } = window

interface QuestionInputMultiProps {
  question: Question
  baseInputName: string
  controlId: string
  response: CustomerSurveyQuestionResponse
}

const QuestionInputMulti: React.FC<QuestionInputMultiProps> = observer(
  ({ question, baseInputName, controlId, response }) => {
    const store = useContext(CustomerSurveyFormContext)

    const surveyInputName = `${baseInputName}[child_customer_surveys_attributes]`
    const [surveyRowCount, setSurveyRowCount] = useState(
      response ? response.child_customer_surveys.length : 1
    )

    const initializedResponses = question.child_survey.questions.map((csq) => {
      return {
        value: '',
        survey_question_id: csq.id,
      }
    })

    // On load, if the survey has not been submitted before,
    // initialize a blank response for each child survey question
    useEffect(() => {
      if (!response) {
        const customerSurveys = [{ responses: initializedResponses }]

        store.updateResponses(question, {
          survey_question_id: question.id,
          child_customer_surveys: customerSurveys,
        })
      }
    }, [])

    const addSurveyRow = () => {
      setSurveyRowCount(surveyRowCount + 1)

      // Add new blank responses for each child survey question
      // and append to the array of child surveys
      const newChildSurveys = [
        ...response.child_customer_surveys,
        { responses: initializedResponses },
      ]
      store.updateResponses(question, {
        survey_question_id: question.id,
        child_customer_surveys: newChildSurveys,
      })
    }

    const multiInputResponseChanged = (value, child_survey_question, row_index) => {
      // find the correct child survey based on the row
      // then find the child survey response for the given question
      const childSurvey = response.child_customer_surveys[row_index]
      const newResponses = [...childSurvey.responses]
      const responseIndex = newResponses.findIndex(
        (r) => r.survey_question_id == child_survey_question.id
      )

      // if the question had a response, copy its attributes but replace the value
      // otherwise create a new response for the question
      if (responseIndex >= 0) {
        const childSurveyResponse = newResponses[responseIndex]
        const newResponse = { ...childSurveyResponse, value: value }

        newResponses.splice(responseIndex, 1, newResponse)
      } else {
        const newResponse = {
          survey_question_id: child_survey_question.id,
          value: value,
        }
        newResponses.push(newResponse)
      }

      // replace the responses for the child survey
      const newChildSurveys = [...response.child_customer_surveys]
      newChildSurveys[row_index].responses = newResponses

      store.updateResponses(question, { ...response, child_customer_surveys: newChildSurveys })
    }

    return (
      <>
        <p className="font-weight-medium mb-2">{question.question}</p>
        {[...Array(surveyRowCount)].map((_, row_index) => {
          const childCustomerSurvey = response ? response.child_customer_surveys[row_index] : null

          return (
            <div key={`childsurvey-${row_index}`} className="bg-gray-100 p-3 my-3">
              <input type="hidden" name={`${baseInputName}[has_child_survey]`} value="1" />
              <input
                type="hidden"
                name={`${surveyInputName}[${row_index}][id]`}
                defaultValue={childCustomerSurvey ? childCustomerSurvey.id : ''}
              />
              <input
                type="hidden"
                name={`${surveyInputName}[${row_index}][survey_id]`}
                defaultValue={question.child_survey_id}
              />
              <input
                type="hidden"
                name={`${surveyInputName}[${row_index}][customer_id]`}
                defaultValue={store.customerId}
              />
              {question.child_survey.questions.map((csq, csq_index) => {
                const csqResponses = childCustomerSurvey ? childCustomerSurvey.responses : []
                const csqResponse = csqResponses.find((csqr) => csqr.survey_question_id == csq.id)
                const responseInputName = `${surveyInputName}[${row_index}][responses_attributes][${csq_index}]`
                const responseControlId = `${controlId}-${row_index}-${csq_index}`

                return (
                  <div key={`childsurvey-${row_index}-${csq_index}`}>
                    <input
                      type="hidden"
                      name={`${responseInputName}[id]`}
                      defaultValue={csqResponse ? csqResponse.id : ''}
                    />
                    <input
                      type="hidden"
                      name={`${responseInputName}[survey_question_id]`}
                      defaultValue={csq.id}
                    />
                    <input type="hidden" name={`${responseInputName}[skipped]`} value="0" />

                    <QuestionResponseInputs
                      question={csq}
                      response={csqResponse}
                      baseInputName={responseInputName}
                      controlId={responseControlId}
                      multiInput={true}
                      onStaticResponseChange={(value) =>
                        multiInputResponseChanged(value, csq, row_index)
                      }
                      onActionableResponseChange={(value) =>
                        multiInputResponseChanged(value, csq, row_index)
                      }
                    />
                  </div>
                )
              })}
            </div>
          )
        })}
        {surveyRowCount < question.times_collected ? (
          <button type="button" className="btn btn-outline-dark" onClick={addSurveyRow}>
            {I18n.t('views.customer_surveys.add_new_entry')}
          </button>
        ) : null}
      </>
    )
  }
)

export default QuestionInputMulti
