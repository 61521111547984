import React, { useState } from 'react'
import { Form, Button, Row, Col } from 'react-bootstrap'
import classNames from 'classnames'

import SectionHeader from '@components/SectionHeader'
import { EventProduct, ProgramProduct } from '@interfaces/events'
import { EventProductForm } from './components'

const { I18n } = window

interface EventPriceSectionProps {
  eventProducts: EventProduct[]
  programProducts: ProgramProduct[]
  onAddEventProduct(programProduct: ProgramProduct): void
  onRemoveEventProduct(eventProduct: EventProduct): void
  onEventPriceUpdate(eventProduct: EventProduct, price: number): void
}

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})
const formatCurrency = (value) => formatter.format(value / 100)

const EventPriceSection: React.FC<EventPriceSectionProps> = ({
  eventProducts,
  programProducts,
  onAddEventProduct,
  onRemoveEventProduct,
  onEventPriceUpdate,
}) => {
  const [selectedProgramProductId, setSelectedProgramProductId] = useState('')

  const optionalProgramProducts = programProducts.filter((pp) => pp.permission_type == 'optional')
  const assignedProgramProductIds = eventProducts.map((ep) => ep.program_product_id)
  const programProductOptions = programProducts.filter(
    (pp) => assignedProgramProductIds.indexOf(pp.id) == -1
  )

  const eventPriceCents = eventProducts
    .filter((product) => !product._destroy)
    .reduce((partialSum, product) => partialSum + Number(product.price_cents), 0)

  const programProductForId = (id) => programProducts.find((p) => p.id == id)
  const assignProductToEvent = () => {
    const programProduct = programProductForId(selectedProgramProductId)
    onAddEventProduct(programProduct)
    setSelectedProgramProductId('')
  }

  return (
    <fieldset>
      <SectionHeader text={I18n.t('views.events.event_price_header')} />
      <p className="font-size-sm text-muted">{I18n.t('views.events.event_price_description')}</p>
      {optionalProgramProducts.length > 0 ? (
        <Row className="d-flex align-items-center">
          <Col xs={6}>
            <Form.Group controlId="events_program">
              <Form.Label>{I18n.t('views.event_products.add_program_products')}</Form.Label>
              <Form.Control
                as="select"
                onChange={(e) => setSelectedProgramProductId(e.currentTarget.value)}
                value={selectedProgramProductId}
              >
                <option></option>
                {programProductOptions.map((p, idx) => (
                  <option value={p.id} key={idx}>
                    {p.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col xs={6}>
            <Button
              disabled={selectedProgramProductId == ''}
              onClick={() => assignProductToEvent()}
              className="mt-3"
            >
              {I18n.t('views.event_products.add_to_event_price')}
            </Button>
          </Col>
        </Row>
      ) : null}

      {eventProducts.length == 0 ? (
        <div className="bg-yellow-200 font-size-sm p-3 my-2">
          <span className="font-weight-medium">
            {I18n.t('views.event_products.event_currently_free')}
          </span>
          &nbsp; {I18n.t('views.event_products.select_a_product')}
        </div>
      ) : (
        eventProducts.map((eventProduct, idx) => {
          return (
            <EventProductForm
              eventProduct={eventProduct}
              index={idx}
              formatCurrency={formatCurrency}
              onRemoveEventProduct={onRemoveEventProduct}
              onEventPriceUpdate={onEventPriceUpdate}
              key={eventProduct.program_product_id}
            />
          )
        })
      )}
      <div
        className={classNames(
          'p-3 mb-3 rounded d-flex justify-content-between align-items-center',
          {
            'bg-gray-100': eventPriceCents == 0,
            'bg-green-100': eventPriceCents > 0,
          }
        )}
      >
        <p className="mb-0">{I18n.t('views.event_products.event_price')}</p>
        <p className="mb-0">{formatCurrency(eventPriceCents)}</p>
      </div>
    </fieldset>
  )
}

export default EventPriceSection
