import React from 'react'
import Badge from 'react-bootstrap/Badge'
import { EventActivity } from '@interfaces/events'
import { parseDateString } from '@utils/strings'
import { onKeyActivate } from '@utils/onKeyActivate'
import { format, utcToZonedTime } from 'date-fns-tz'

const { I18n } = window
const contractTimeZone = window.__STOREFRONT__.timeZone

interface EventDateTimeChipProps {
  index: number
  eventActivity: EventActivity
  onClick(): void
  contractTimeZone?: string
}

const formatActivityChipTime = (e: EventActivity) => {
  const start = utcToZonedTime(parseDateString(e.start_at), contractTimeZone)
  const end = utcToZonedTime(parseDateString(e.end_at), contractTimeZone)
  const date = format(start, I18n.t('date.formats.chip'))
  const from = format(start, 'h:mm a')
  const to = format(end, 'h:mm a - zzz', {
    timeZone: contractTimeZone,
  })

  return `${date} - ${from} TO ${to}`
}

const EventDateTimeChip: React.FC<EventDateTimeChipProps> = ({ index, eventActivity, onClick }) => {
  if (eventActivity._destroy) {
    return (
      <>
        <input
          type="hidden"
          value={eventActivity.id}
          name={`program_event[event_activities_attributes][${index}][id]`}
        />
        <input
          type="hidden"
          value="1"
          name={`program_event[event_activities_attributes][${index}][_destroy]`}
        />
      </>
    )
  } else {
    return (
      <Badge key={index} variant="primary" className="p-3 m-2 chip">
        {formatActivityChipTime(eventActivity)}
        <span
          className="mdi mdi-window-close pl-2 white-text cursor-pointer"
          role="button"
          onClick={onClick}
          tabIndex={0}
          onKeyDown={onKeyActivate('click')}
          aria-label={I18n.t('views.events.delete_event_date')}></span>
        <input
          type="hidden"
          value={eventActivity.id}
          name={`program_event[event_activities_attributes][${index}][id]`}
        />
        <input
          type="hidden"
          value={eventActivity.start_at}
          name={`program_event[event_activities_attributes][${index}][start_at]`}
        />
        <input
          type="hidden"
          value={eventActivity.end_at}
          name={`program_event[event_activities_attributes][${index}][end_at]`}
        />
      </Badge>
    )
  }
}

export default EventDateTimeChip
