// Datetime Picker with built in timezone support
// Expects a UTC string value
// Returns an array of UTC string values

import 'flatpickr/dist/themes/airbnb.css'
import React from 'react'
import Flatpickr from 'react-flatpickr'
import l10n from 'flatpickr/dist/l10n'
import KeForms from '@app/interfaces/KeForms'
import { utcToZonedTime, format, toDate } from 'date-fns-tz'

const { I18n } = window

const FormDatetimePicker: React.FC<KeForms.DatetimePicker> = ({
  label,
  value,
  placeholder,
  disabled,
  onChange,
  name,
  className = '',
}) => {
  const contractTimeZone = window.__STOREFRONT__.timeZone
  const timezonlessDatetimeFormat = "yyyy-MM-dd'T'HH:mm:ss"
  const displayDateFormat = I18n.t('date.formats.date_fns_datetime')

  // This function is only for display in the flatpickr input
  const formatDate = (date) => {
    // date is a date object with the correct time for the contract time zone
    // but it is assigned the system time zone by flatpickr
    //
    // Format the date with the contract time zone
    // without changing the underlying datetime
    const formattedTime = format(date, displayDateFormat, {
      timeZone: contractTimeZone,
    })
    return formattedTime
  }

  const parsedValue = (value) => {
    if (value) {
      // take utc date string and convert it to the contracts time zone
      const contractDate = utcToZonedTime(value, contractTimeZone)
      // return ISO string representation of the time for flatpickr
      return contractDate.toISOString()
    } else {
      return null
    }
  }

  const onChangeOverride = (dates) => {
    if (onChange) {
      const tzDates = dates.map((date) => {
        // date is a Date object with the correct chosen time value in the system time zone
        // ex: Wed Dec 01 2021 12:00:00 GMT-0600 (Central Standard Time)

        // extract a datetime string without system time zone information
        // ex: 2021-12-01T12:00:00
        const formattedDate = format(date, timezonlessDatetimeFormat)

        // convert the datetime string to the equivalent time in the contracts time zone
        // ex: contract time zone is America/Denver
        // ex: Wed Dec 01 2021 13:00:00 GMT-0600 (Central Standard Time)
        const tzDate = toDate(formattedDate, { timeZone: contractTimeZone })

        // return ISO string representation of the converted date
        // ex: 2021-12-01T19:00:00.000Z
        return tzDate.toISOString()
      })

      onChange(tzDates)
    }
  }

  return (
    <div className={`form-group ${className}`}>
      <label className="form-label d-block">
        {label}
        <div className="input-group mt-2">
          <Flatpickr
            className="form-control bg-white"
            data-enable-time
            options={{
              locale: l10n[I18n.language],
              formatDate: formatDate,
            }}
            value={parsedValue(value)}
            placeholder={placeholder}
            onChange={onChangeOverride}
            disabled={disabled}
          />
          {!disabled && (
            <div className="input-group-append">
              <span className="input-group-text mdi mdi-calendar" aria-hidden="true"></span>
            </div>
          )}
          {name ? <input type="hidden" value={value || ''} name={name} /> : null}
        </div>
      </label>
    </div>
  )
}

export default FormDatetimePicker
