import { AddressProps } from '@interfaces/global'
import { useState, useEffect } from 'react'

// Toggles two addresses between mailing and/or physical based on the
// "Different physical address" checkbox
type SetAddressType = [AddressProps[], boolean, React.Dispatch<React.SetStateAction<boolean>>]

const useSetAddressType = (defaultAddrs: AddressProps[]) => {
  const bothExist = !!(defaultAddrs[0] && defaultAddrs[1])
  const [showBoth, setShowBoth] = useState(bothExist)
  const [addresses, setAddresses] = useState(defaultAddrs)

  useEffect(() => {
    const addrs = [...addresses]

    if (showBoth) {
      addrs[0].physical = 0
      addrs[1] = { ...(addrs[1] || {}), mailing: 0, physical: 1, _destroy: 0 }
    } else {
      addrs[0] = { ...addrs[0], mailing: 1, physical: 1 }
      if (addrs[1] && addrs[1].id) {
        addrs[1]._destroy = 1
      } else {
        delete addrs[1]
      }
    }

    setAddresses(addrs)
  }, [showBoth])

  return [addresses, showBoth, setShowBoth] as SetAddressType
}

export default useSetAddressType
