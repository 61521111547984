import React from 'react'
import { Form, Row, Col } from 'react-bootstrap'
import { Question, CustomerSurveyQuestionResponse } from '@interfaces/surveys'

interface QuestionInputHeightWeightProps {
  question: Question
  baseInputName: string
  controlId: string
  response: CustomerSurveyQuestionResponse
  handleResponseChange(value: string): void
  multiInput?: boolean
}

const QuestionInputHeightWeight: React.FC<QuestionInputHeightWeightProps> = ({
  question,
  baseInputName,
  controlId,
  response,
  handleResponseChange,
  multiInput,
}) => {
  const labelClass = multiInput ? 'font-size-sm' : 'font-weight-medium'

  return (
    <Row>
      <Col xs={6}>
        <Form.Group controlId={controlId}>
          <Form.Label className={labelClass}>
            {question.question} {question.required || multiInput ? '*' : null}
          </Form.Label>
          <Form.Control
            type="text"
            name={`${baseInputName}[value]`}
            onChange={(e) => handleResponseChange(e.currentTarget.value)}
            required={question.required}
            defaultValue={response ? response.value : ''}
          />
        </Form.Group>
      </Col>
    </Row>
  )
}

export default QuestionInputHeightWeight
