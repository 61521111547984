import KeForms from '@app/interfaces/KeForms'

import React from 'react'
import Form from 'react-bootstrap/Form'

const FormSelect: React.FC<KeForms.Select> = ({
  index,
  defaultOption = true,
  options,
  ...defaults
}) => {
  const allOptions = options.map((option) => (
    <option key={`${index}-${option[1]}`} value={option[1]}>
      {option[0]}
    </option>
  ))

  if (defaultOption) {
    allOptions.unshift(
      <option key={`${index}-default`} value={''} disabled>
        Select {defaults.placeholder}
      </option>
    )
  }

  return (
    <Form.Control as="select" {...defaults} defaultValue={defaults.defaultValue || ''}>
      {allOptions}
    </Form.Control>
  )
}

export default FormSelect
