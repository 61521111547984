import React, { Component } from 'react'
import L from 'leaflet'
import ProgramLocationProps from '@interfaces/ProgramLocationProps'

const { I18n } = window

class LocationInfoControl extends L.Control {
  locationModel: ProgramLocationProps

  constructor(location: ProgramLocationProps, controlOptions: L.ControlOptions) {
    super(controlOptions)
    this.locationModel = location
  }

  onAdd = (): HTMLElement => {
    const div = L.DomUtil.create('div')
    div.className = 'location-tooltip custom-control'
    div.style.width = '200px'
    div.innerHTML = `
      <address>
        ${this.locationModel.formatted_address}
      </address>
      <a href="https://www.google.com/maps/search/?api=1&query=${this.locationModel.latitude},${
      this.locationModel.longitude
    }" target="_blank">
        ${I18n.t('views.ke_map.view_on_google')}
      </a>
    `

    return div
  }
}

class ResetMarkerButton extends L.Control {
  callback: () => void
  defaultVisibility: boolean

  constructor(callback: () => void, controlOptions: L.ControlOptions, defaultVisibility: boolean) {
    super(controlOptions)
    this.callback = callback
    this.defaultVisibility = defaultVisibility
  }

  onAdd = (): HTMLButtonElement => {
    const button = L.DomUtil.create('button') as HTMLButtonElement
    button.className = 'reset-marker-button leaflet-touch leaflet-bar custom-control'
    button.style.display = this.defaultVisibility ? 'block' : 'none'
    button.type = 'button'
    button.onclick = this.callback
    button.innerText = I18n.t('views.ke_map.reset_marker')

    return button
  }
}

type SearchResponse = {
  status: string
  display_status: string
  street: string
  city: string
  state: string
  country: string
  postal_code: string
  county: string
  formatted_address: string
  partial_match: string
  latitude: number
  longitude: number
}

type KeMapLayerGroups = {
  fallback: L.LayerGroup
  street: L.LayerGroup
  satellite: L.LayerGroup
  hybrid: L.LayerGroup
}

type KeMapProps = {
  height: number
  defaultLat: number
  defaultLng: number
  defaultZoom: number
  mappableContainerId: string
  geocodedLat: number
  geocodedLng: number
  draggableMarker: boolean
  locationModel: ProgramLocationProps
  showLocationInfoControl: boolean
  markerMoveLatTargetSelector: string
  markerMoveLngTargetSelector: string
}

type KeMapState = {
  map: L.Map
  mapId: string
  loading: boolean
  resetMarkerEnabled: boolean
  errorMessage: string
  marker: L.Marker
  geocodedLat: number
  geocodedLng: number
  mappableLat: number
  mappableLng: number
  draggableMarker: boolean
  mappableFields: object
  geocodeUpdateTimeout: number
  layers: KeMapLayerGroups
}

class KeMap extends Component<KeMapProps, KeMapState> {
  public static defaultProps = {
    height: 400,
    defaultLat: 39,
    defaultLng: -100,
    defaultZoom: 3,
    draggableMarker: false,
  }

  private loader: React.RefObject<HTMLDivElement>

  constructor(props: KeMapProps) {
    super(props)
    this.loader = React.createRef()

    this.state = {
      map: null,
      mapId: `leaflet-map-${Date.now()}`,
      errorMessage: '',
      loading: true,
      resetMarkerEnabled: false,
      marker: null,
      geocodedLat: null,
      geocodedLng: null,
      mappableLat: null,
      mappableLng: null,
      draggableMarker: props.draggableMarker || false,
      mappableFields: {},
      geocodeUpdateTimeout: null,
      layers: {
        fallback: this.initFallbackLayers(),
        street: this.initStreetLayers(),
        satellite: this.initSatelliteLayers(),
        hybrid: this.initHybridLayers(),
      },
    }
  }

  get mappableAddressFromState(): string {
    return `${this.state.mappableFields['addr1']}, ${this.state.mappableFields['city']}, ${this.state.mappableFields['state_code']} ${this.state.mappableFields['postal_code']}`
  }

  get map(): L.Map {
    return this.state.map
  }

  get marker(): L.Marker {
    return this.state.marker
  }

  render(): React.ReactNode {
    return (
      <React.Fragment>
        <p>{this.state.errorMessage}</p>
        <div className="ke-map static-map">
          <div id={this.state.mapId} style={{ height: this.props.height + 'px' }}>
            <div className="map-loader" ref={this.loader}>
              <div className="spinner-border text-light" role="status">
                <span className="sr-only">{I18n.t('views.ke_map.loading')}</span>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }

  componentDidMount(): void {
    let coords = new L.LatLng(this.props.defaultLat, this.props.defaultLng)
    let zoom = this.props.defaultZoom

    if (
      this.props.locationModel != null &&
      this.props.locationModel.latitude != null &&
      this.props.locationModel.longitude != null
    ) {
      coords = L.latLng(this.props.locationModel.latitude, this.props.locationModel.longitude)
      zoom = this.props.defaultZoom
    }

    this.initializeMap(coords, zoom)

    if (this.props.mappableContainerId != null) {
      this.populateExistingMappableFields()
      this.addMapableFieldListeners()
    }
  }

  initializeMap = (coords: L.LatLng, zoom: number): void => {
    const map = L.map(this.state.mapId, {
      // Options Here
      attributionControl: false, // Overide so we can set prefix: false
      zoomControl: false, // override to translate zoom titles
    }).setView(coords, zoom)

    L.control
      .zoom({
        zoomInTitle: I18n.t('views.ke_map.zoom_in'),
        zoomOutTitle: I18n.t('views.ke_map.zoom_out'),
      })
      .addTo(map)

    L.control
      .attribution({
        prefix: false,
      })
      .addTo(map)

    if (this.props.locationModel != null && this.props.showLocationInfoControl) {
      const locationTooltip = new LocationInfoControl(this.props.locationModel, {
        position: 'topright',
      } as L.ControlOptions)
      locationTooltip.addTo(map)
    }

    this.setupLayers(map)

    const marker = L.marker(coords, {
      draggable: this.state.draggableMarker,
    }).addTo(map)
    marker.addEventListener('moveend', this.handleMarkerMove)

    let enableResetMarkerButton = false
    if (
      this.props.geocodedLat != null &&
      this.props.geocodedLng != null &&
      !coords.equals(L.latLng(this.props.geocodedLat, this.props.geocodedLng))
    ) {
      enableResetMarkerButton = true
    }

    if (this.state.draggableMarker) {
      const resetMarkerButton = new ResetMarkerButton(
        this.resetMarker.bind(this),
        { position: 'bottomright' } as L.ControlOptions,
        enableResetMarkerButton
      )
      resetMarkerButton.addTo(map)
    }

    const stateMutation = {
      map: map,
      mapId: this.state.mapId,
      loading: this.state.loading,
      marker: marker,
      resetMarkerEnabled: enableResetMarkerButton,
      geocodedLat: this.state.geocodedLat,
      geocodedLng: this.state.geocodedLng,
    }
    if (this.props.geocodedLat != null && this.props.geocodedLng != null) {
      stateMutation.geocodedLat = this.props.geocodedLat
      stateMutation.geocodedLng = this.props.geocodedLng
    }
    if (stateMutation.geocodedLat == null && stateMutation.geocodedLng == null) {
      stateMutation.geocodedLat = coords.lat
      stateMutation.geocodedLng = coords.lng
    }

    this.setState(stateMutation, () => {
      this.hideLoader()
    })
  }

  disableMap = (): void => {
    this.map.dragging.disable()
    this.map.touchZoom.disable()
    this.map.doubleClickZoom.disable()
    this.map.scrollWheelZoom.disable()
    this.map.boxZoom.disable()
    this.map.keyboard.disable()
    if (this.map.tap) this.map.tap.disable()
    document.getElementById(this.state.mapId).style.cursor = 'default'
  }

  enableMap = (): void => {
    this.map.dragging.enable()
    this.map.touchZoom.enable()
    this.map.doubleClickZoom.enable()
    this.map.scrollWheelZoom.enable()
    this.map.boxZoom.enable()
    this.map.keyboard.enable()
    if (this.map.tap) this.map.tap.enable()
    document.getElementById(this.state.mapId).style.cursor = 'grab'
  }

  showLoader = (): void => {
    this.setState({ loading: true }, () => {
      this.loader.current.style.display = 'flex'
      this.disableMap()
    })
  }

  hideLoader = (): void => {
    this.setState({ loading: false }, () => {
      this.loader.current.style.display = 'none'
      this.enableMap()
    })
  }

  resetMarker = (): void => {
    this.marker.setLatLng(L.latLng(this.state.geocodedLat, this.state.geocodedLng))

    const newLat = String(this.state.geocodedLat)
    const newLong = String(this.state.geocodedLng)
    this.setFormFieldLatLong(newLat, newLong)

    const resetMarkerButton = document.querySelector(
      `#${this.state.mapId} .reset-marker-button`
    ) as HTMLElement
    resetMarkerButton.style.display = 'none' as string
    this.setState({
      resetMarkerEnabled: false,
    })
  }

  populateExistingMappableFields = (): void => {
    const mappableFields = document.querySelectorAll(
      `#${this.props.mappableContainerId} [data-mappable-field]`
    )
    const stateMutation = {}
    mappableFields.forEach((field, _index) => {
      stateMutation[field.getAttribute('data-mappable-field')] = (field as HTMLInputElement).value
    })
    this.setState({ mappableFields: stateMutation }, this.handleGeocodeUpdate)
  }

  addMapableFieldListeners = (): void => {
    const mappableFields = document.querySelectorAll(
      `#${this.props.mappableContainerId} [data-mappable-field]`
    )
    mappableFields.forEach((field, _index) => {
      field.addEventListener('keyup', (e) => {
        const eventTarget = e.target as HTMLInputElement
        this.setState(
          {
            mappableFields: {
              ...this.state.mappableFields,
              [eventTarget.getAttribute('data-mappable-field')]: eventTarget.value,
            },
          },
          () => {
            clearTimeout(this.state.geocodeUpdateTimeout)
            this.setState({
              geocodeUpdateTimeout: window.setTimeout(this.handleGeocodeUpdate, 600),
            })
          }
        )
      })
    })
  }

  handleMarkerMove = (e: L.DragEndEvent): void => {
    if (
      this.props.markerMoveLatTargetSelector != null &&
      this.props.markerMoveLngTargetSelector != null
    ) {
      const markerNewCoords = L.latLng(e.target._latlng.lat, e.target._latlng.lng)
      const newLat = markerNewCoords.lat.toString()
      const newLong = markerNewCoords.lng.toString()
      this.setFormFieldLatLong(newLat, newLong)

      if (!this.state.resetMarkerEnabled) {
        const resetMarkerButton = document.querySelector(
          `#${this.state.mapId} .reset-marker-button`
        ) as HTMLElement
        resetMarkerButton.style.display = 'block'
        this.setState({
          resetMarkerEnabled: true,
        })
      }
    }
  }

  handleGeocodeUpdate = (): void => {
    if (this.hasSearchableDetails) {
      this.showLoader()
      fetch(`/places/search?q=${encodeURIComponent(this.mappableAddressFromState)}`)
        .then(this.checkStatus)
        .then((resp: Response) => resp.json())
        .then(this.throwNoResults)
        .then(this.setMapLatLong)
        .then(this.clearErrorMsg)
        .catch((e) => {
          this.setState({ errorMessage: e.message })
        })
        .finally(this.hideLoader)
    }
  }

  get hasSearchableDetails(): boolean {
    const fields = this.state.mappableFields

    return fields['addr1'] && (fields['postal_code'] || (fields['city'] && fields['state_code']))
  }

  checkStatus = (response: Response): Response => {
    if (response.status != 200) throw { message: I18n.t('views.ke_map.something_went_wrong') }

    return response
  }

  throwNoResults = (parsed: SearchResponse): SearchResponse => {
    if (parsed.status === 'No Results') {
      throw { message: parsed.display_status }
    }
    return parsed
  }

  setMapLatLong = (parsed: SearchResponse): void => {
    const { latitude, longitude } = parsed

    if (this.state.geocodedLat != latitude || this.state.geocodedLng != longitude) {
      this.setState(
        {
          mappableLat: latitude,
          mappableLng: longitude,
          geocodedLat: latitude,
          geocodedLng: longitude,
        },
        () => {
          const coords = L.latLng(this.state.geocodedLat, this.state.geocodedLng)
          this.map.setView(coords, 15)
          this.marker.setLatLng(coords)

          const newLat = String(this.state.geocodedLat)
          const newLong = String(this.state.geocodedLng)
          this.setFormFieldLatLong(newLat, newLong)
        }
      )
    }
  }

  setFormFieldLatLong = (latitude: string, longitude: string): void => {
    const latTarget = document.querySelector(
      this.props.markerMoveLatTargetSelector
    ) as HTMLInputElement

    const lngTarget = document.querySelector(
      this.props.markerMoveLngTargetSelector
    ) as HTMLInputElement

    latTarget.value = latitude
    lngTarget.value = longitude
  }

  clearErrorMsg = (): void => {
    this.setState({ errorMessage: '' })
  }

  setupLayers = (map: L.Map): void => {
    // In case one of the "main" layer groups goes offline, we fallback to these
    this.state.layers.fallback.addTo(map)
    // Default Layer Group
    this.state.layers.street.addTo(map)
    // Setup selectable Layer Groups
    L.control
      .layers({
        [I18n.t('views.ke_map.street_layer')]: this.state.layers.street,
        [I18n.t('views.ke_map.satellite_layer')]: this.state.layers.satellite,
        [I18n.t('views.ke_map.hybrid_layer')]: this.state.layers.hybrid,
      })
      .addTo(map)
  }

  initFallbackLayers = (): L.LayerGroup => {
    return L.layerGroup([
      // Kalkomey Maps
      L.tileLayer('https://maps.kalkomey.com/hot/{z}/{x}/{y}.png', {
        maxZoom: 19,
      }),
      // OpenStreetMap Latest
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 19,
      }),
    ])
  }

  initStreetLayers = (): L.LayerGroup => {
    return L.layerGroup([
      // Stamen Terrain Base
      L.tileLayer('https://stamen-tiles-{s}.a.ssl.fastly.net/terrain/{z}/{x}/{y}.png', {
        maxZoom: 17,
        attribution: I18n.t('views.ke_map.attribution_html'),
      }),
      // Stamen Watercolor
      L.tileLayer('https://stamen-tiles-{s}.a.ssl.fastly.net/watercolor/{z}/{x}/{y}.jpg', {
        maxZoom: 17,
        minZoom: 1,
      }),
      // Stamen Terrain Overlay
      L.tileLayer('https://stamen-tiles-{s}.a.ssl.fastly.net/terrain/{z}/{x}/{y}.png', {
        maxZoom: 17,
        opacity: 0.45,
        className: 'mix-multiple',
      }),
      // Stamen Terrain Labels
      L.tileLayer('https://stamen-tiles-{s}.a.ssl.fastly.net/terrain-labels/{z}/{x}/{y}.png', {
        maxZoom: 17,
      }),
    ])
  }

  initSatelliteLayers = (): L.LayerGroup => {
    return L.layerGroup([
      // Esri World Imagery
      L.tileLayer(
        'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
        {
          maxZoom: 19,
          attribution: `Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping,
        Aerogrid, IGN, IGP, UPR-EGP, GIS Editors`,
        }
      ),
      // Esri World Boundaries And Places
      L.tileLayer(
        'https://server.arcgisonline.com/ArcGIS/rest/services/Reference/World_Boundaries_And_Places/MapServer/tile/{z}/{y}/{x}',
        {
          maxZoom: 19,
        }
      ),
    ])
  }

  initHybridLayers = (): L.LayerGroup => {
    return L.layerGroup([
      // Esri World Imagery
      L.tileLayer(
        'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
        {
          maxZoom: 19,
          attribution: `Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping,
        Aerogrid, IGN, IGP, UPR-EGP, GIS Editors`,
        }
      ),
      // Esri World Boundaries And Places
      L.tileLayer(
        'https://server.arcgisonline.com/ArcGIS/rest/services/Reference/World_Boundaries_And_Places/MapServer/tile/{z}/{y}/{x}',
        {
          maxZoom: 19,
        }
      ),
      // Esri World Transportation
      L.tileLayer(
        'https://server.arcgisonline.com/ArcGIS/rest/services/Reference/World_Transportation/MapServer/tile/{z}/{y}/{x}',
        {
          maxZoom: 19,
        }
      ),
    ])
  }
}

export default KeMap
