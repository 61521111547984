import React from 'react'
import Form from 'react-bootstrap/Form'
import { EventsProgram } from '@interfaces/events'

import SectionHeader from '@components/SectionHeader'

const { I18n } = window

interface EventAgeRequirementsSectionProps {
  program: EventsProgram
  onAgeRestrictionChangeEvent(propertyKey: string, e: React.ChangeEvent<HTMLInputElement>): void
  minAgeDateCalculationOptions: string[][]
  maxAgeDateCalculationOptions: string[][]
  minAge: string
  minAgeDateCalculation: string
  minAgeDisplayText: string
  maxAge: string
  maxAgeDateCalculation: string
  maxAgeDisplayText: string
}

const EventAgeRequirementsSection: React.FC<EventAgeRequirementsSectionProps> = ({
  onAgeRestrictionChangeEvent,
  minAgeDateCalculationOptions,
  maxAgeDateCalculationOptions,
  minAge,
  minAgeDateCalculation,
  minAgeDisplayText,
  maxAge,
  maxAgeDateCalculation,
  maxAgeDisplayText,
}) => {
  return (
    <fieldset>
      <SectionHeader text={I18n.t('views.events.age_requirements')} />
      <div className="form-row">
        <div className="col-lg-3">
          <Form.Group controlId="min_age">
            <Form.Label>{I18n.t('views.events.min_age')}</Form.Label>
            <Form.Control
              type="text"
              value={minAge}
              name="program_event[min_age]"
              onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                onAgeRestrictionChangeEvent('minAge', e)
              }
            />
          </Form.Group>
        </div>
      </div>
      <Form.Group controlId="min_age_display_text">
        <Form.Label>{I18n.t('views.events.min_policy')}</Form.Label>
        <p>{I18n.t('views.events.min_policy_explanation')}</p>
        <Form.Control
          type="text"
          as="textarea"
          placeholder={I18n.t('views.events.min_display')}
          name="program_event[min_age_display_text]"
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
            onAgeRestrictionChangeEvent('minAgeDisplayText', e)
          }
          value={minAgeDisplayText}
          rows={5}
        />
      </Form.Group>
      <div className="form-row">
        <div className="col-lg-6">
          <Form.Group controlId="min_age_date_calculation">
            <Form.Label>{I18n.t('views.events.min_age_date')}</Form.Label>
            <Form.Control
              as="select"
              name="program_event[min_age_date_calculation]"
              onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                onAgeRestrictionChangeEvent('minAgeDateCalculation', e)
              }
              value={minAgeDateCalculation}
              required={!!minAge}>
              <option></option>
              {minAgeDateCalculationOptions.map((p, idx) => (
                <option value={p[0]} key={idx}>
                  {p[1]}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </div>
      </div>
      <div className="form-row">
        <div className="col-lg-3">
          <Form.Group controlId="max_age">
            <Form.Label>{I18n.t('views.events.max_age')}</Form.Label>
            <Form.Control
              type="text"
              value={maxAge}
              name="program_event[max_age]"
              onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                onAgeRestrictionChangeEvent('maxAge', e)
              }
            />
          </Form.Group>
        </div>
      </div>
      <Form.Group controlId="max_age_display_text">
        <Form.Label>{I18n.t('views.events.max_policy')}</Form.Label>
        <p>{I18n.t('views.events.max_policy_explanation')}</p>
        <Form.Control
          type="text"
          as="textarea"
          placeholder={I18n.t('views.events.max_display')}
          name="program_event[max_age_display_text]"
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
            onAgeRestrictionChangeEvent('maxAgeDisplayText', e)
          }
          value={maxAgeDisplayText}
          rows={5}
        />
      </Form.Group>
      <div className="form-row">
        <div className="col-lg-6">
          <Form.Group controlId="max_age_date_calculation">
            <Form.Label>{I18n.t('views.events.max_age_date')}</Form.Label>
            <Form.Control
              as="select"
              name="program_event[max_age_date_calculation]"
              onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                onAgeRestrictionChangeEvent('maxAgeDateCalculation', e)
              }
              value={maxAgeDateCalculation}
              required={!!maxAge}>
              <option></option>
              {maxAgeDateCalculationOptions.map((p, idx) => (
                <option value={p[0]} key={idx}>
                  {p[1]}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </div>
      </div>
    </fieldset>
  )
}

export default EventAgeRequirementsSection
