import React from 'react'

interface FeatherIconProps {
  // Name of the icon from the Feather SVG sprite
  name: string
  className?: string
  alt?: string
}

const FeatherIcon: React.FC<FeatherIconProps> = ({
  name,
  className = '',
  alt = '',
  ...otherProps
}) => {
  const featherAssetPath = (window as any).__STOREFRONT__.featherSvgPath
  const altPresent = alt && alt.length !== 0

  if (!altPresent) {
    otherProps['aria-hidden'] = 'true'
  }

  return name ? (
    <svg
      viewBox="0 0 30 30"
      className={`feather-icon ${className}`}
      focusable="false"
      role="img"
      {...otherProps}>
      {altPresent ? <title>{alt}</title> : null}
      <use href={`${featherAssetPath}#${name}`} aria-hidden="true"></use>
    </svg>
  ) : null
}

export default FeatherIcon
