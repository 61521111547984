import React, { useState } from 'react'
import { ProgramLocation } from '@interfaces/events'
import LocationSection from './LocationSection'

interface LocationSectionWrapperProps {
  programLocation: ProgramLocation
  capacity: number
  materialOrderShippingLocation: string
  materialOrderShippingLocationDisabled: boolean
}

const LocationSectionWrapper: React.FC<LocationSectionWrapperProps> = ({
  programLocation,
  capacity,
  materialOrderShippingLocation,
  materialOrderShippingLocationDisabled,
}) => {
  const [currentCapacity, setCurrentCapacity] = useState(capacity)
  const [currentMaterialShipping, setCurrentMaterialShipping] = useState(
    materialOrderShippingLocation
  )

  return (
    <LocationSection
      programLocation={programLocation}
      onCapacityChange={(e): void => setCurrentCapacity(parseInt(e.target.value, 10) || 0)}
      capacity={currentCapacity}
      setCapacity={setCurrentCapacity}
      onMaterialOrderShippingLocation={(e): void => setCurrentMaterialShipping(e.target.value)}
      materialOrderShippingLocation={currentMaterialShipping}
      materialOrderShippingLocationDisabled={materialOrderShippingLocationDisabled}
    />
  )
}

export default LocationSectionWrapper
