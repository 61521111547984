import React from 'react'
import { Form } from 'react-bootstrap'

const { I18n } = window

interface EventPoliciesConfirmedProps {
  customerName: string
  primarySelected?: boolean
  isInvalid?: boolean
}

const EventPoliciesConfirmed: React.FC<EventPoliciesConfirmedProps> = ({
  customerName,
  primarySelected = true,
  isInvalid = false,
}) => {
  const labelKey = primarySelected
    ? 'views.events.policies_understood'
    : 'views.events.policies_understood_on_behalf'

  return (
    <div className="bg-green-100 p-3 rounded">
      <Form.Group className="m-0" controlId="event_policies_confirmed">
        <Form.Check
          value="1"
          type="checkbox"
          isInvalid={isInvalid}
          name="event_registration[policies_confirmed]"
          label={I18n.t(labelKey, { name: customerName })}
        />
      </Form.Group>
    </div>
  )
}

export default EventPoliciesConfirmed
