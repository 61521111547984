import React from 'react'
import { Form } from 'react-bootstrap'
import { Question, CustomerSurveyQuestionResponse } from '@interfaces/surveys'

interface QuestionInputDropdownProps {
  question: Question
  baseInputName: string
  controlId: string
  response: CustomerSurveyQuestionResponse
  handleResponseChange(value: string): void
  multiInput?: boolean
}

const QuestionInputDropdown: React.FC<QuestionInputDropdownProps> = ({
  question,
  baseInputName,
  controlId,
  response,
  handleResponseChange,
  multiInput,
}) => {
  const labelClass = multiInput ? 'font-size-sm' : 'font-weight-medium'

  return (
    <Form.Group controlId={controlId}>
      <Form.Label className={labelClass}>
        {question.question} {question.required || multiInput ? '*' : null}
      </Form.Label>
      <Form.Control
        as="select"
        name={`${baseInputName}[value]`}
        onChange={(e) => handleResponseChange(e.currentTarget.value)}
        required={question.required}
        defaultValue={response ? response.value : ''}>
        <option key={`default-${controlId}`}></option>
        {question.question_responses.map((qr, idx) => (
          <option value={qr.value} key={`${controlId}-${idx}`}>
            {qr.value}
          </option>
        ))}
      </Form.Control>
    </Form.Group>
  )
}

export default QuestionInputDropdown
