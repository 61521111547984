import React, { useState } from 'react'
import Form from 'react-bootstrap/Form'
import SectionHeader from '@components/SectionHeader'

const { I18n } = window

interface NotificationSectionProps {
  registrationNotifications: boolean
  cancellationNotifications: boolean
}

const NotificationSection: React.FC<NotificationSectionProps> = ({
  registrationNotifications,
  cancellationNotifications,
}) => {
  const [registrationEnabled, setRegistrationEnabled] = useState(registrationNotifications || false)
  const [cancellationEnabled, setCancellationEnabled] = useState(cancellationNotifications || false)

  return (
    <fieldset>
      <SectionHeader text={I18n.t('views.events.notification_options')} />

      <Form.Group controlId="registration_notifications">
        <Form.Check
          type="checkbox"
          label={I18n.t('views.events.registration')}
          defaultChecked={registrationNotifications}
          onChange={(e) => setRegistrationEnabled(e.target.checked)}
        />
        <input
          type="hidden"
          name="program_event[registration_notifications]"
          value={registrationEnabled ? '1' : '0'}
        />
        <Form.Text className="text-muted">
          {I18n.t('views.events.registration_explanation')}
        </Form.Text>
      </Form.Group>

      <Form.Group controlId="cancellation_notifications">
        <Form.Check
          type="checkbox"
          label={I18n.t('views.events.cancellation')}
          defaultChecked={cancellationNotifications}
          onChange={(e) => setCancellationEnabled(e.target.checked)}
        />
        <input
          type="hidden"
          name="program_event[cancellation_notifications]"
          value={cancellationEnabled ? '1' : '0'}
        />
        <Form.Text className="text-muted">
          {I18n.t('views.events.cancellation_explanation')}
        </Form.Text>
      </Form.Group>
    </fieldset>
  )
}

export default NotificationSection
