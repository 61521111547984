import React from 'react'
import Form from 'react-bootstrap/Form'

import SectionHeader from '@components/SectionHeader'

const { I18n } = window

interface EventWaitlistSectionProps {
  onChange(e: React.ChangeEvent<HTMLInputElement>): void
  onWaitlistEnabled(e: React.ChangeEvent<HTMLInputElement>): void
  waitlistEnabled: boolean
  value: number
  maxCapacity: number
  disabled: boolean
}

const EventWaitlistSection: React.FC<EventWaitlistSectionProps> = ({
  onChange,
  onWaitlistEnabled,
  waitlistEnabled,
  value,
  maxCapacity,
  disabled,
}) => {
  return (
    <fieldset>
      <SectionHeader text={I18n.t('views.events.waitlist_options')} />
      <Form.Group controlId="enable_waitlist">
        <Form.Check
          type="checkbox"
          disabled={disabled}
          label={I18n.t('views.events.enable_waitlist')}
          className="mb-3"
          checked={waitlistEnabled}
          onChange={onWaitlistEnabled}
        />
      </Form.Group>
      <Form.Group controlId="waitlist_capacity">
        <Form.Label>{I18n.t('views.events.waitlist_capacity')}</Form.Label>
        <Form.Control
          required={!disabled}
          disabled={disabled}
          type="number"
          placeholder={I18n.t('views.events.waitlist_capacity')}
          name="program_event[waitlist]"
          onChange={onChange}
          value={`${value}`}
          isInvalid={value > maxCapacity || value < 0}
        />
        <Form.Control.Feedback type="invalid">
          {value > maxCapacity && I18n.t('views.events.waitlist_not_gt', { maxCapacity })}
          {value < 0 && I18n.t('views.events.waitlist_must_gt')}
        </Form.Control.Feedback>
      </Form.Group>
    </fieldset>
  )
}

export default EventWaitlistSection
