import React from 'react'
import { Form } from 'react-bootstrap'
import { Question, CustomerSurveyQuestionResponse } from '@interfaces/surveys'

interface QuestionInputRadioButtonProps {
  question: Question
  baseInputName: string
  controlId: string
  response: CustomerSurveyQuestionResponse
  handleResponseChange(value: string): void
  multiInput?: boolean
}

const QuestionInputRadioButton: React.FC<QuestionInputRadioButtonProps> = ({
  question,
  baseInputName,
  controlId,
  response,
  handleResponseChange,
  multiInput,
}) => {
  const labelClass = multiInput ? 'font-size-sm' : 'font-weight-medium'

  return (
    <Form.Group controlId={controlId}>
      <Form.Label className={labelClass}>
        {question.question} {question.required || multiInput ? '*' : null}
      </Form.Label>
      {question.question_responses.map((qr, idx) => (
        <Form.Check
          key={`${controlId}-${idx}`}
          id={`${controlId}-${idx}`}
          label={qr.value}
          value={qr.value}
          type="radio"
          defaultChecked={(response ? response.value : '') === qr.value}
          name={`${baseInputName}[value]`}
          onChange={(e) => handleResponseChange(e.currentTarget.value)}
          required={question.required}
        />
      ))}
    </Form.Group>
  )
}

export default QuestionInputRadioButton
