// Disables a link on click and replaces link text
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["disabledLink", "disabledText"]

  disabledLinkTarget: HTMLAnchorElement
  disabledTextTarget: HTMLSpanElement

  disableLink() {
    this.disabledLinkTarget.addEventListener("click", this.preventClick.bind(this))
    this.disabledLinkTarget.classList.add("disabled")
    this.disabledLinkTarget.innerHTML = this.disabledLinkText()
  }

  disabledLinkText() {
    return this.disabledTextTarget.innerText
  }

  preventClick(event) {
    event.preventDefault()
  }

}
