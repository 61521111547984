import React from 'react'
import Form from 'react-bootstrap/Form'

const { I18n } = window

interface EventNameInputFieldProps {
  onChange(e: React.ChangeEvent<HTMLInputElement>): void
  disabled: boolean
  value: string
}

const EventNameInputField: React.FC<EventNameInputFieldProps> = ({ onChange, disabled, value }) => {
  return (
    <Form.Group controlId="event_name">
      <Form.Label>{I18n.t('views.events.name')}</Form.Label>
      <Form.Control
        type="text"
        value={value}
        disabled={disabled}
        name="program_event[event_name]"
        onChange={onChange}
      />
    </Form.Group>
  )
}

export default EventNameInputField
