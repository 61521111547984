import React, { useState } from 'react'

import SectionHeader from '@components/SectionHeader'
import { EventActivity } from '@interfaces/events'
import { parseDateString } from '@utils/strings'
import FormDatetimePicker from '@ke-forms/FormDatetimePicker'
import { EventDateTimeChip } from './components'

const { I18n } = window

interface EventActivitiesFormProps {
  onAddEventActivity(eventActivity: EventActivity): void
  onRemoveEventActivity(idx: number): void
  eventActivities: EventActivity[]
}

const EventActivitiesForm: React.FC<EventActivitiesFormProps> = ({
  onAddEventActivity,
  onRemoveEventActivity,
  eventActivities,
}) => {
  const INITIAL_STATE: EventActivity = { start_at: null, end_at: null }
  const [data, setData] = useState(INITIAL_STATE)

  const parsedStart = parseDateString(data.start_at)
  const parsedEnd = parseDateString(data.end_at)

  const hasTimes = data.start_at && data.end_at
  const isEndBeforeStart = hasTimes && parsedStart >= parsedEnd

  const containsEventTime = (date: Date): boolean =>
    eventActivities.some(
      (eventActivity) =>
        parseDateString(eventActivity.start_at).getTime() <= date.getTime() &&
        parseDateString(eventActivity.end_at).getTime() >= date.getTime()
    )

  const isOverlapping = hasTimes && (containsEventTime(parsedStart) || containsEventTime(parsedEnd))
  const isInvalid = isEndBeforeStart || isOverlapping
  const errorText = isEndBeforeStart
    ? I18n.t('views.events.end_before_start')
    : I18n.t('views.events.overlapping')

  return (
    <fieldset>
      <SectionHeader text={I18n.t('views.events.schedule')} />

      <div className="form-row">
        <FormDatetimePicker
          id="event_start_at"
          label={I18n.t('views.events.starts_at')}
          placeholder={I18n.t('views.events.start_time')}
          value={data.start_at}
          onChange={(changes): void => setData({ ...data, start_at: changes[0] })}
          className="col-lg-6"
        />

        <FormDatetimePicker
          id="event_end_at"
          label={I18n.t('views.events.ends_at')}
          placeholder={I18n.t('views.events.end_time')}
          value={data.end_at}
          onChange={(changes): void => setData({ ...data, end_at: changes[0] })}
          className="col-lg-6"
        />
      </div>

      <button
        type="button"
        disabled={!data.start_at || !data.end_at || isInvalid}
        className="btn btn-sm btn-primary btn-block rounded py-2"
        onClick={(): void => {
          onAddEventActivity(data)
          setData(INITIAL_STATE)
        }}>
        <span className="mdi mdi-plus-thick pr-1" aria-hidden="true"></span>
        {I18n.t('views.events.add_date')}
      </button>

      {isInvalid && <div className="text-danger text-center pt-3">{errorText}</div>}

      <div className="my-2">
        {eventActivities.map((activity, idx) => (
          <EventDateTimeChip
            index={idx}
            key={`activity-${idx}-${activity.id}`}
            eventActivity={activity}
            onClick={(): void => onRemoveEventActivity(idx)}
          />
        ))}
      </div>
    </fieldset>
  )
}

export default EventActivitiesForm
