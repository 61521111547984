import React, { Fragment } from 'react'
import { Button } from 'react-bootstrap'
import Portal from '@components/shared/Portal'
import EventPoliciesConfirmed from './EventPoliciesConfirmed'
import WaiverConfirmation from './WaiverConfirmation'

const { I18n } = window

const ParticipantRegistrationPrivate: React.FC<any> = ({
  account,
  editing,
  registration_customer_id,
  errors,
  waiver,
  waiver_settings,
  registration_waivers,
  can_add_participants,
}) => {
  const submitDisabled = !account.age_eligible.is_eligible
  const showWaiver = waiver && waiver_settings.waiver_enforcement === 'enforce'
  return (
    <Fragment>
      <input type="hidden" name="event_registration[customer_id]" value={account.id} />

      <div className="bg-gray-100 p-3 rounded">
        <span className="text-gray-900 font-weight-medium mr-3">{account.full_name}</span>
        <span className="text-gray-700">
          {I18n.t('views.users.born_in', { year: account.dob_year || '' })}
        </span>
      </div>

      {account.age_eligible.message ? (
        <div className="text-danger font-size-sm">
          {I18n.t('views.events.registration_age_eligible')}
        </div>
      ) : (
        undefined
      )}

      <Portal mountId="event-registration-actions-portal">
        <Button type="submit" className="btn btn-primary btn-sm-block" disabled={submitDisabled}>
          {editing ? I18n.t('common.save_and_continue') : I18n.t('common.continue')}
        </Button>
      </Portal>

      <Portal mountId="event-registration-actions-portal">
        {showWaiver ? (
          <WaiverConfirmation
            customer={account}
            waiver={waiver}
            submitDisabled={submitDisabled}
            registrationWaivers={registration_waivers}
            addingParticipant={false}
            can_add_participants={can_add_participants}
          />
        ) : (
          <Button type="submit" className="btn btn-primary btn-sm-block" disabled={submitDisabled}>
            {registration_customer_id
              ? I18n.t('common.save_and_continue')
              : I18n.t('common.continue')}
          </Button>
        )}
      </Portal>

      <Portal mountId="policies-confirmed-portal">
        <EventPoliciesConfirmed
          customerName={account.full_name}
          isInvalid={errors && !!errors.policies_confirmed}
        />
      </Portal>
    </Fragment>
  )
}

export default ParticipantRegistrationPrivate
