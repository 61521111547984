import { format } from 'date-fns-tz'
import { parseISO } from 'date-fns'
// Pluralize a given string based on count
//
// Usage:
//
//   <p>{pluralize('Occupant', 2)}, {pluralize('Vehicle', 1)}</p>
//
// Displays: Occupants, Vehicle
//

export const pluralize = (text: string, count: number) => {
  if (count == 1) {
    return text
  } else {
    return `${text}s`
  }
}

// Display a count and pluralized string
//
// Usage:
//
//   <p>{countDisplay('Occupant', 2)}, {countDisplay('Vehicle', 1)}</p>
//
// Displays: 2 Occupants, 1 Vehicle
//

export const countDisplay = (text: string, count: number) => {
  return `${count} ${pluralize(text, count)}`
}

export const getNumeric = (value) => (!!+value ? +value : 0)

export const formatCurrency = (value) => getNumeric(value).toFixed(2)

export const formatDollars = (value) => {
  const amount = getNumeric(value)

  // Display negative dollars, e.g. "-$5.00"
  if (amount < 0) {
    return `-$${Math.abs(amount).toFixed(2)}`
  } else {
    return `$${amount.toFixed(2)}`
  }
}

// Will return the time zone string example: America/New_York will be EST
export const timeZoneString = (date: Date): string => {
  return date && format(date, 'zzz', { timeZone: (window as any).__STOREFRONT__.timeZone })
}

// Guarantees a Date object from a string or Date
export const parseDateString = (date: string | Date): Date => {
  if (date) {
    return typeof date === 'string' ? parseISO(date) : date
  } else {
    return null
  }
}

// Guarantees a boolean from a string or boolean
export const parseBooleanString = (bool: string | boolean): boolean => {
  if (typeof bool === 'boolean') {
    return bool
  } else {
    return bool === 'true'
  }
}
