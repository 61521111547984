import { createContext } from 'react'
import { useLocalStore } from 'mobx-react-lite'
import { Question, CustomerSurveyQuestionResponse } from '@interfaces/surveys'

export const CustomerSurveyFormContext = createContext(null)

export const createSurveyFormStore = (
  questions: Question[],
  responses: CustomerSurveyQuestionResponse[],
  customerId: number
) => {
  // Initially skip any questions that have a skipped response
  const mappedQuestions = questions.slice().map((question) => {
    const response = responses.find((r) => r.survey_question_id == question.id)
    question.skipped = response && Boolean(Number(response.skipped))
    return question
  })

  const store = useLocalStore(() => ({
    questions: mappedQuestions,
    responses,
    customerId,
    updateResponses(question, newResponse) {
      const newResponses = [...this.responses]
      const i = newResponses.findIndex((r) => r.survey_question_id == question.id)

      if (i >= 0) {
        newResponses.splice(i, 1, newResponse)
      } else {
        newResponses.push(newResponse)
      }

      this.responses = newResponses
    },
    changeResponseValue(question, existingResponse, value) {
      const newResponse = existingResponse
        ? { ...existingResponse, value: value }
        : {
            survey_question_id: question.id,
            value: value,
          }

      this.updateResponses(question, newResponse)
    },
    handleOptionSelected(question, existingResponse, value, index) {
      const questionResponse = question.question_responses.find((r) => r.value == value)

      // treat blank values like default "next_question" behavior
      const nextAction = questionResponse ? questionResponse.next_action : 'next_question'
      const nextQuestionId = questionResponse ? questionResponse.next_question_id : null

      this.handleNextAction(nextAction, nextQuestionId, index)
      this.changeResponseValue(question, existingResponse, value)
    },
    handleNextAction(nextAction, nextQuestionId, index) {
      const currentIndex = index + 1
      const questions = this.questions

      if (nextAction == 'end_survey') {
        // Skip all questions after the current question
        for (let i = currentIndex; i < questions.length; i++) {
          const question = questions[i]
          question.skipped = true
        }
      } else if (nextAction == 'go_to_question') {
        // Skip all questions before the "go to question"
        let skipped = true
        for (let i = currentIndex; i < questions.length; i++) {
          const question = questions[i]
          if (question.id == nextQuestionId) {
            skipped = false
          }
          question.skipped = skipped
          if (this.questionHasNextActionBehavior(question)) {
            break
          }
        }
      } else {
        // un-skip all questions after the current question
        for (let i = currentIndex; i < questions.length; i++) {
          const question = questions[i]
          question.skipped = false
          if (this.questionHasNextActionBehavior(question)) {
            break
          }
        }
      }

      this.questions = questions
    },
    // If a question has a "go to question" or "end survey" next action we need to
    // break so we dont change the question visibility for subsequent questions
    questionHasNextActionBehavior(question) {
      let hasNextAction = false
      const response = this.responses.find((r) => r.survey_question_id == question.id)
      if (response) {
        const questionResponse = question.question_responses.find((r) => r.value == response.value)

        if (questionResponse && questionResponse.next_action != 'next_question') {
          hasNextAction = true
        }
      }
      return hasNextAction
    },
  }))

  return store
}
