import React from 'react'
import Form from 'react-bootstrap/Form'

import SectionHeader from '@components/SectionHeader'

const { I18n } = window

interface EventDetailsSectionProps {
  onAboutChangeEvent(e: React.ChangeEvent<HTMLInputElement>): void
  onRegistrationChangeEvent(e: React.ChangeEvent<HTMLInputElement>): void
  aboutThisEvent: string
  registrationConfirmationMessage: string
}

const EventDetailsSection: React.FC<EventDetailsSectionProps> = ({
  onAboutChangeEvent,
  onRegistrationChangeEvent,
  aboutThisEvent,
  registrationConfirmationMessage,
}) => (
  <fieldset>
    <SectionHeader text={I18n.t('views.events.event_details')} />

    <Form.Group controlId="event_description">
      <Form.Label>{I18n.t('views.events.about')}</Form.Label>
      <Form.Control
        required
        type="text"
        as="textarea"
        name="program_event[description]"
        onChange={onAboutChangeEvent}
        value={aboutThisEvent}
        rows={5}
      />
    </Form.Group>

    <Form.Group controlId="reg_confirmation_message">
      <Form.Label>{I18n.t('views.events.registration_confirmation_message')}</Form.Label>
      <Form.Control
        required
        type="text"
        as="textarea"
        name="program_event[registration_confirmation_message]"
        onChange={onRegistrationChangeEvent}
        value={registrationConfirmationMessage}
        rows={5}
      />
    </Form.Group>
  </fieldset>
)

export default EventDetailsSection