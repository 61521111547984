import React from 'react'
import Flatpickr from 'react-flatpickr'
import l10n from 'flatpickr/dist/l10n'
import { Question, CustomerSurveyQuestionResponse } from '@interfaces/surveys'

const { I18n } = window

interface QuestionInputDateProps {
  question: Question
  baseInputName: string
  response: CustomerSurveyQuestionResponse
  handleResponseChange(value: string): void
  multiInput?: boolean
}

const QuestionInputDate: React.FC<QuestionInputDateProps> = ({
  question,
  baseInputName,
  response,
  handleResponseChange,
  multiInput,
}) => {
  const labelClass = multiInput ? 'font-size-sm' : 'font-weight-medium'

  return (
    <div className="form-group">
      <label className={`form-label ${labelClass}`}>
        {question.question} {question.required || multiInput ? '*' : null}
        <div className="input-group mt-2">
          <Flatpickr
            className="form-control bg-white"
            options={{
              locale: l10n[I18n.language],
              dateFormat: I18n.t('date.formats.flatpickr_date'),
            }}
            name={`${baseInputName}[value]`}
            onChange={(dates) => handleResponseChange(dates[0])}
            required={question.required}
            defaultValue={response ? response.value : ''}
          />

          <div className="input-group-append">
            <span className="input-group-text mdi mdi-calendar" aria-hidden="true"></span>
          </div>
        </div>
      </label>
    </div>
  )
}

export default QuestionInputDate
