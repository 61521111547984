import { AddressProps, SelectOption } from '@interfaces/global'
import React, { Fragment } from 'react'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import FormGroup from '@ke-forms/FormGroup'
import { FormContext } from '@ke-forms/FormContext'

const { I18n } = window

interface AddressFieldsProps {
  idx: number
  address: AddressProps
  baseName: string
  stateOptions: SelectOption[]
  stateLabel: string
  validated: boolean
  children?: React.ReactNode
}

const AddressFields: React.FC<AddressFieldsProps> = ({
  idx,
  address,
  baseName,
  stateOptions,
  stateLabel,
  validated,
  children,
}) => {
  const context = {
    baseName: `${baseName}[${idx}]`,
    index: idx,
    errors: address.errors,
    validated: validated,
  }

  return (
    <Fragment key={`address-${idx}`}>
      <FormContext.Provider value={context}>
        <Form.Row>
          <Col lg={9}>
            <FormGroup
              label={I18n.t('activemodel.attributes.address.address1')}
              inputName="address1"
              inputClass="form-control-lg"
              required
              defaultValue={address.address1}
              autoComplete="address-line1"
            />
          </Col>
          <Col lg={3}>
            <FormGroup
              label={I18n.t('activemodel.attributes.address.address2')}
              inputName="address2"
              inputClass="form-control-lg"
              defaultValue={address.address2}
              autoComplete="address-line2"
            />
          </Col>
        </Form.Row>

        <Form.Row>
          <Col xl={6}>
            <FormGroup
              label={I18n.t('activemodel.attributes.address.city')}
              inputName="city"
              inputClass="form-control-lg"
              required
              defaultValue={address.city}
              autoComplete="address-level2"
            />
          </Col>
          <Col xl={6}>
            <FormGroup
              type="select"
              options={stateOptions}
              label={stateLabel}
              inputName="subregion_code"
              inputClass="form-control-lg"
              required
              defaultValue={address.subregion_code}
              autoComplete="address-level1"
            />
          </Col>
        </Form.Row>
        <Form.Row>
          <Col lg={5} xl={4}>
            <FormGroup
              label={I18n.t('activemodel.attributes.address.postal_code')}
              inputName="postal_code"
              inputClass="form-control-lg"
              required
              defaultValue={address.postal_code}
              autoComplete="postal-code"
            />
          </Col>
        </Form.Row>

        <input type="hidden" value={address.id || ''} name={`${baseName}[${idx}][id]`} />
        <input type="hidden" value={address.mailing || ''} name={`${baseName}[${idx}][mailing]`} />
        <input
          type="hidden"
          value={address.physical || ''}
          name={`${baseName}[${idx}][physical]`}
        />

        {children}
      </FormContext.Provider>
    </Fragment>
  )
}

export default AddressFields
